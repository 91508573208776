import { TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { getHandlerKayPress } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { Options, useFieldProps } from './use-field-props';

interface Props {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
}

type Shared = TextFieldProps & {
  onChangeItem: () => void;
};

export const useFilterInputProps = (shared: Shared) => {
  const getFieldProps = useFieldProps();
  const { onChangeItem, ...rest } = shared;
  return useCallback(
    (props: Props, options?: Options) => {
      const { field } = props;
      return {
        ...rest,
        ...getFieldProps(props, options),
        helperText: undefined,
        onKeyPress: getHandlerKayPress('Enter', onChangeItem),
        onBlur: composeFunctions(field.onBlur, onChangeItem),
        onClear: onChangeItem,
      };
    },
    [rest, getFieldProps, onChangeItem],
  );
};

import { IIncomeAccounts } from 'services/income-accounts';
import { PaymentType } from 'services/payment-types';
import { IUserPharmacistProfileInvoices } from 'services/user-pharmacist-profile-invoices';
import { IUserPharmacistProfile } from 'services/user-pharmacist-profiles';
import { DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';

const API = 'UserPharmacistProfileInvoiceDetails';

export const API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPharmacistProfileInvoiceDetails, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPharmacistProfileInvoiceDetails, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface UserPharmacistProfileInvoiceDetail
  extends DynamicComponents.Schemas.UserPharmacistProfileInvoiceDetail {
  id: string;
}
export const schemaUserPharmacistProfileInvoiceDetail = yup.object({
  quantity: yup
    .number()
    .integer('rule-integer')
    .min(1, 'rule-min')
    .required('rule-required')
    .default(1),
  paymentTypeID: yup.string().required('rule-required').default(''),
  incomeAccountID: yup.string().required('rule-required').default(''),
  amount: yup.number().min(0, 'rule-min').required('rule-required').min(0, 'rule-min').default(0),
  userPharmacistProfileID: yup.string().required('rule-required').default(''),
  userPharmacistProfileInvoiceID: yup.string().required('rule-required').default(''),
  remarks: yup
    .string()
    .when('$remarkRequired', {
      is: true,
      then: (schema) => schema.required('rule-required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    })
    .required('rule-required')
    .default(''),
  discountAmount: yup.number().notRequired().default(0),
  discountPercentage: yup.number().min(0, 'rule-min').max(100, 'rule-max').notRequired().default(0),
  fromDate: yup.string().notRequired().nullable().default(null),
  toDate: yup.string().notRequired().nullable().default(null),
  duration: yup.number().notRequired().nullable().default(0),
  score: yup.string().notRequired().nullable().default(null),
});
export class UserPharmacistProfileInvoiceDetails
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoiceDetail>
{
  id!: string;
  rowIndex!: number;
  isActive: boolean = true;
  userPharmacistProfileID!: string;
  paymentTypeID!: string;
  incomeAccountID!: string;
  invoiceNumber = '';
  invoiceDate = new Date().toISOString();
  quantity = 1;
  amount = 0;
  fromDate = new Date().toISOString();
  toDate = new Date().toISOString();
  remarks = '';
  paidBy = '';
  score = '';
  status = '';
  quality = '';
  duration = 0;
  contribution = '';
  sector = '';
  userPharmacistProfileInvoiceID = '';
  discount?: number | string;
  discountPercentage!: number;
  discountAmount!: number;
}
export interface IUserPharmacistProfileInvoiceDetails extends UserPharmacistProfileInvoiceDetails {
  userPharmacistProfileInvoice: IUserPharmacistProfileInvoices;
  userPharmacistProfile: IUserPharmacistProfile;
  paymentType: PaymentType;
  incomeAccount: IIncomeAccounts;
  accountCode: string;
  discountPercentage: number;
  discountAmount: number;
  'userPharmacistProfileInvoice.invoiceType.step': string;
}
export interface IUserPharmacistProfilePaymentSubscription
  extends Pick<IUserPharmacistProfileInvoiceDetails, 'fromDate' | 'toDate'> {
  paymentTypeTitle: string;
}
export interface IGridUserPharmacistProfileInvoiceDetails
  extends Pick<
    IUserPharmacistProfileInvoiceDetails,
    | 'id'
    | 'invoiceDate'
    | 'invoiceNumber'
    | 'quantity'
    | 'amount'
    | 'fromDate'
    | 'toDate'
    | 'remarks'
    | 'userPharmacistProfileInvoiceID'
    | 'discountPercentage'
    | 'discountAmount'
  > {
  paymentTypeTitle: string;
  paymentTypeID: string;
  incomeAccountID: string;
  userPharmacistProfileID: string;
  appIdentityUserID: string;
  paidBy: string;
  userPharmacistProfileFullName: string;
  incomeAccountCode: string;
  incomeTitle: string;
  userPharmacistProfilePaidByName: string;
  accountCode: string;
  incomeAccountTitle: string;
  showRemarks: boolean;
  rowNumber: number;
  pharmacistProfileInvoiceID: string;
}

export interface IGridPharmacistsActivities
  extends Pick<
    IUserPharmacistProfileInvoiceDetails,
    'id' | 'quantity' | 'amount' | 'fromDate' | 'toDate'
  > {
  invoiceNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['invoiceNumber'];
  paymentDate: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['paymentDate'];
  paidBy: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['fullName'];
  paidByProfileID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfileID'];
  idNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['idNumber'];
  paidFor: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['fullName'];
  phoneMobile: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['phoneMobile'];
  email: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['email'];
  paymentTypeTitle: IUserPharmacistProfileInvoiceDetails['paymentType']['title'];
  incomeAccountTitle: IUserPharmacistProfileInvoiceDetails['incomeAccount']['title'];
  invoiceDate: string;
  invoiceTypeTitle: string;
  userPharmacistProfileID: string;
  paidForIdNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['idNumber'];
  pharmacistProfileInvoiceID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['id'];
}

export interface IGridPharmacistsActivitiesFilters {
  search: string;
  paidByID: string;
  paidForID: string;
  invoiceNumber: string;
  incomeAccountID: string[];
  paymentTypeID: string[];
  date: DateValue[];
  active: boolean | null;
}
export interface IGridPharmacistsActivitiesArgs extends IGridPharmacistsActivitiesFilters {
  take: number;
  skip: number;
  order: DynamicOrder;
}

export interface AvailableInvoiceNumbersSource {
  id: string;
  title: string;
}

export interface IGridYearlySubscriptions
  extends Pick<IUserPharmacistProfileInvoiceDetails, 'id' | 'quantity' | 'amount'> {
  invoiceNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['invoiceNumber'];
  toDate: IUserPharmacistProfileInvoiceDetails['toDate'];
  fromDate: IUserPharmacistProfileInvoiceDetails['fromDate'];
  paidBy: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['fullName'];
  paidByProfileID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfileID'];
  idNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['idNumber'];
  paidFor: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['fullName'];
  phoneMobile: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['phoneMobile'];
  email: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['email'];
  paymentTypeTitle: IUserPharmacistProfileInvoiceDetails['paymentType']['title'];
  incomeAccountTitle: IUserPharmacistProfileInvoiceDetails['incomeAccount']['title'];
  invoiceDate: string;
  invoiceTypeTitle: string;
  userPharmacistProfileID: string;
  paidForIdNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['idNumber'];
  pharmacistProfileInvoiceID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['id'];
}

export interface IGridYearlySubscriptionsFilters {
  search: string;
  paidByID: string;
  paidForID: string;
  paymentTypeID: string[];
  year: string;
  active: boolean | null;
}
export interface IGridYearlySubscriptionsArgs extends IGridYearlySubscriptionsFilters {
  take: number;
  skip: number;
  order: DynamicOrder;
}

import { isEqual, pick } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';
interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
  extends UseFormProps<TFieldValues, TContext> {
  onUpdate?: (value: TFieldValues) => void;
}
export const useFilterForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  props: Props<TFieldValues, TContext>,
) => {
  const result = useForm(props);

  const { defaultValues, onUpdate } = props;

  const { reset, getValues, trigger } = result;

  useEffect(() => {
    // @ts-ignore
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onChangeItem = useCallback(async () => {
    if (!onUpdate) return;
    const values = getValues();

    const oldValues = pick(defaultValues, Object.keys(values));

    if (isEqual(oldValues, values)) return;

    const isValid = await trigger();

    if (!isValid) return;

    onUpdate(values);
  }, [getValues, defaultValues, onUpdate, trigger]);

  return { ...result, onChangeItem };
};

import { Button, ButtonProps, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import clsx from 'clsx';
import React, { CSSProperties, useMemo } from 'react';
import style from './index.module.scss';

interface Props extends ButtonProps {}

export const ButtonTonal: React.FC<Props> = ({ title, color = 'primary', children, ...rest }) => {
  const { palette } = useTheme();
  const paletteColor = color === 'inherit' ? null : palette[color];
  const styles = useMemo(() => {
    if (!paletteColor) {
      return undefined;
    }
    return {
      '--button-color': paletteColor.main,
      '--button-color-light': alpha(paletteColor.light, 0.05),
    } as CSSProperties;
  }, [paletteColor]);
  return (
    <Button
      variant={'contained'}
      color={color}
      {...rest}
      style={{ ...styles, ...rest.style }}
      className={clsx(style.root, rest.className)}
    >
      {children}
    </Button>
  );
};

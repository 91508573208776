import { ButtonsSwitcher } from 'components/buttons-switcher';

import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { enumToArray } from 'utils/other';
import { EVENT_SESSION_STATUS } from './helpers';

interface Props {
  value: EVENT_SESSION_STATUS;
  onChange: (value: this['value']) => void;
  disabled?: boolean;
}
export const EventSessionStatus: React.FC<Props> = ({ value, onChange, disabled }) => {
  const { t } = useTranslate();
  const items = useMemo(() => {
    return enumToArray(EVENT_SESSION_STATUS).map(({ id }) => {
      return { value: id, children: t(id) };
    });
  }, [t]);

  return (
    <ButtonsSwitcher
      items={items}
      value={value}
      onChange={onChange}
      ButtonsProps={{ size: 'medium' }}
      disabled={disabled}
    />
  );
};

export * from './helpers';

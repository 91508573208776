import { Box } from '@mui/material';
import { HeaderPortal } from 'components/header-portal';
import { HeaderTitle } from 'components/header-title';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const GeneralSettingsLayout = memo(() => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.SETTINGS_GENERAL.path,
        to: APP_ROUTES.SETTINGS_GENERAL().config,
        label: tp('payment-type-subject'),
      },
      {
        value: APP_ROUTES.PAYMENT_TYPES.path,
        to: APP_ROUTES.PAYMENT_TYPES().config,
        label: tp('payment-types'),
      },
      {
        value: APP_ROUTES.INCOME_ACCOUNTS.path,
        to: APP_ROUTES.INCOME_ACCOUNTS().config,
        label: tp('income-accounts'),
      },
      {
        value: APP_ROUTES.INVOICE_TYPES.path,
        to: APP_ROUTES.INVOICE_TYPES().config,
        label: tp('invoice-types'),
      },
      {
        value: APP_ROUTES.PAYMENT_ACCOUNTS.path,
        to: APP_ROUTES.PAYMENT_ACCOUNTS().config,
        label: tp('payment-accounts'),
      },
      {
        value: APP_ROUTES.MEMBER_TYPES.path,
        to: APP_ROUTES.MEMBER_TYPES().config,
        label: tp('member-types'),
      },
      {
        value: APP_ROUTES.NAME_PREFIXES.path,
        to: APP_ROUTES.NAME_PREFIXES().config,
        label: tp('name-prefixes'),
      },
      {
        value: APP_ROUTES.NAME_PREFIXES_ENG.path,
        to: APP_ROUTES.NAME_PREFIXES_ENG().config,
        label: tp('name-prefixes-eng'),
      },
      {
        value: APP_ROUTES.NATIONALITIES.path,
        to: APP_ROUTES.NATIONALITIES().config,
        label: tp('nationalities'),
      },
      {
        value: APP_ROUTES.REGIONS.path,
        to: APP_ROUTES.REGIONS().config,
        label: tp('regions'),
      },
      {
        value: APP_ROUTES.CITIES.path,
        to: APP_ROUTES.CITIES().config,
        label: tp('cities'),
      },
      {
        value: APP_ROUTES.WORK_PLACES.path,
        to: APP_ROUTES.WORK_PLACES().config,
        label: tp('work-places'),
      },
      {
        value: APP_ROUTES.WORK_PLACE_TYPES.path,
        to: APP_ROUTES.WORK_PLACE_TYPES().config,
        label: tp('work-place-types'),
      },
      {
        value: APP_ROUTES.CUSTOMER_TYPES.path,
        to: APP_ROUTES.CUSTOMER_TYPES().config,
        label: tp('customer-types'),
      },
      {
        value: APP_ROUTES.EVENT_SESSION_CATEGORIES.path,
        to: APP_ROUTES.EVENT_SESSION_CATEGORIES().config,
        label: tp('event-session-categories'),
      },
      {
        value: APP_ROUTES.SMS_MESSAGES.path,
        to: APP_ROUTES.SMS_MESSAGES().config,
        label: tp('sms-messages'),
      },
      {
        value: APP_ROUTES.EMAIL_MESSAGES.path,
        to: APP_ROUTES.EMAIL_MESSAGES().config,
        label: tp('email-messages'),
      },
    ];
  }, [tp]);
  return (
    <>
      <HeaderPortal>
        <HeaderTitle children={tp('general-settings')} />
      </HeaderPortal>
      <Box className={style.inner}>
        <TabsPage tabs={tabs} />
      </Box>
    </>
  );
});

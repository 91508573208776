import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { FixServerObject } from 'utils/types';

const API = 'IncomeAccounts';

export const API_INCOME_ACCOUNTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IIncomeAccounts, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IIncomeAccounts, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IIncomeAccounts
  extends FixServerObject<DynamicComponents.Schemas.IncomeAccount, 'id'> {}

export const schemaIncomeAccounts = yup.object({
  title: yup.string().required('rule-required').default(''),
  accountCode: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export interface IGridIncomeAccounts
  extends Pick<IIncomeAccounts, 'id' | 'title' | 'accountCode' | 'rowIndex' | 'isActive'> {}

export interface IDashboardIncomeAccountsArgs {
  search: string;
  orderBy: DynamicOrder;
}
export interface IDashboardIncomeAccount
  extends Pick<IIncomeAccounts, 'id' | 'title' | 'accountCode'> {}

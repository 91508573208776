import * as yup from 'yup';

const API = 'UserEmployeeProfiles';

export const API_USER_EMPLOYEE_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserEmployeeProfile, 'appIdentityUserID'>) =>
    `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<UserEmployeeProfile, 'appIdentityUserID'>) =>
    `${API}/Delete/${data.appIdentityUserID}`,
};

export interface IUserEmployeeProfile extends DynamicComponents.Schemas.UserEmployeeProfile {
  appIdentityUserID: string;
}

export const schemaUserEmployeeProfile = yup.object({
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length').trim().default(''),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length').trim().default(''),
  email: yup.string().required('rule-required').email('rule-email').trim().default(''),
  languageID: yup.string().required('rule-required').default(''),
  userEmployeeProfilePermissionID: yup.string().required('rule-required').default(''),
  idNumber: yup.string().required('rule-required').default(''),
  mobilePhone: yup.string().required('rule-required').trim().default(''),
  isActive: yup.boolean().notRequired().nonNullable().default(true),
});

export class UserEmployeeProfile implements yup.InferType<typeof schemaUserEmployeeProfile> {
  id!: string;
  isActive = true;
  appIdentityUserID!: string;
  languageID!: string;
  email = '';
  mobilePhone = '';
  firstName = '';
  lastName = '';
  generatePasswordEndDate = '';
  userEmployeeProfilePermissionID!: string;
  idNumber = '';
}

export interface IDashboardTreatingPharmacistsKPIs extends Pick<UserEmployeeProfile, 'id'> {
  consultSessionsCount: number;
}

import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'PaymentTypeSubjects';

export const API_PAYMENT_TYPE_SUBJECTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IPaymentTypeSubjects, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IPaymentTypeSubjects, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IPaymentTypeSubjects
  extends FixServerObject<DynamicComponents.Schemas.PaymentTypeSubject, 'id'> {}

export const schemaPaymentTypeSubjects = yup.object({
  title: yup.string().required('rule-required').default(''),
  defaultAmount: yup.number().nullable().default(null),
  defaultPaymentTitle: yup.string().nullable().default(null),
  isMembership: yup.boolean().default(false),
  isEvent: yup.boolean().default(false),
  isActive: yup.boolean().default(true),
});

export interface IGridPaymentTypeSubjects
  extends Pick<
    IPaymentTypeSubjects,
    | 'id'
    | 'title'
    | 'rowIndex'
    | 'isActive'
    | 'defaultPaymentTitle'
    | 'defaultAmount'
    | 'isMembership'
    | 'isEvent'
  > {
  numberOfPharmacists: number;
}

import { Button, ButtonGroup, ButtonGroupProps, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import style from './index.module.scss';

interface Item<T> extends Omit<ButtonProps, 'value'> {
  value: T;
}

interface Props<T> extends Omit<ButtonGroupProps, 'onChange'> {
  items: Item<T>[];
  value: T;
  onChange: (value: this['value']) => void;
  color?: 'primary' | 'secondary';
  ButtonsProps?: Omit<ButtonProps, 'value'>;
}

export const ButtonsSwitcher = <T,>({
  value: mainValue,
  onChange,
  items,
  color = 'primary',
  ButtonsProps,
  ...rest
}: Props<T>) => {
  const factoryClick = useCallback(
    (val: T) => {
      return () => onChange(val);
    },
    [onChange],
  );

  const colorClass = useMemo(() => {
    switch (color) {
      case 'secondary': {
        return style.secondary;
      }
      default:
        return null;
    }
  }, [color]);

  return (
    <ButtonGroup variant={'outlined'} {...rest}>
      {items.map(({ value, ...rest }) => {
        const active = mainValue === value;
        return (
          <Button
            onClick={factoryClick(value)}
            key={String(value)}
            size={'small'}
            color={active ? color : undefined}
            variant={active ? 'contained' : undefined}
            {...ButtonsProps}
            {...rest}
            className={clsx(
              active && style.buttonActive,
              style.button,
              colorClass,
              ButtonsProps?.className,
            )}
          />
        );
      })}
    </ButtonGroup>
  );
};

import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useDetectLanguage, useTranslate } from 'hooks';
import loginBackground from 'images/login-background.svg';
import logoWhite from 'images/logo-white.svg';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import clsx from 'clsx';
import { AppImage } from 'components/app-image';
import { LOGO_TEXT } from 'configs/const';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { isLtr } = useDetectLanguage();

  return (
    <Box className={style.wrap}>
      <Box className={style.backgroundImage}>
        {!isMobile && (
          <img
            className={clsx(style.image, isLtr && style.imageLtr)}
            src={loginBackground}
            alt={''}
          />
        )}
      </Box>
      <Box className={style.headerWrapper}>
        <Box className={style.header}>
          <Box className={style.headerText}>
            <Typography component={'h1'} variant={'h2'} color={'white'}>
              {LOGO_TEXT}
            </Typography>
            <Typography component={'h1'} variant={'h2'} color={'white'}>
              The Pharmaceutical Society of Israel
            </Typography>
          </Box>
          {!isMobile && <AppImage src={logoWhite} alt={''} />}
        </Box>
      </Box>
      <Box className={style.content}>
        <Box className={style.form}>
          <Outlet />
        </Box>
      </Box>
      <Box className={style.footer}>
        <Typography
          component={'div'}
          variant={'caption'}
          textAlign={'center'}
          color={VARS.colorGrey}
        >
          {t('copyright')}
        </Typography>
      </Box>
    </Box>
  );
});

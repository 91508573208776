import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'WorkPlaceTypes';

export const API_WORK_PLACE_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<WorkPlaceType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<WorkPlaceType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IWorkPlaceType
  extends FixServerObject<DynamicComponents.Schemas.WorkPlaceType, 'id'> {}

export const schemaWorkPlaceTypes = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export class WorkPlaceType {
  id!: string;
  title = '';
  isActive = true;
}

export interface IGridWorkPlaceType
  extends Pick<IWorkPlaceType, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}

import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ConsultSessions';

export const API_SESSIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IConsultSession, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IConsultSession, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IGridConsultSessions
  extends Pick<
    FixServerObject<DynamicComponents.Schemas.ConsultSession, 'id'>,
    | 'id'
    | 'entryDate'
    | 'patientName'
    | 'consultationDate'
    | 'patientPhone'
    | 'patientEmail'
    | 'patientIDNumber'
    | 'patientAgeYears'
    | 'patientAgeMonths'
    | 'stepIndex'
    | 'lockSession'
    | 'userEmployeeProfile'
    | 'referralReason'
    | 'knownAllergies'
    | 'medicalStaffName'
    | 'answer'
    | 'answeredByEmail'
    | 'answeredByPhone'
    | 'answeredByOther'
    | 'referralTypeOther'
    | 'referralType'
  > {
  insuranceStatus: string | undefined;
  employee: DynamicComponents.Schemas.UserEmployeeProfile['firstName'];
  gender: DynamicComponents.Schemas.Gender['title'];
  age: number | null | undefined;
  consultRequestTypeID: string | undefined;
  userEmployeeProfileID: string | undefined;
  consultRequestTypeTitle: DynamicComponents.Schemas.ConsultRequestType['title'];
  patientPdf: string | undefined;
  medicalStaffPdf: string | undefined;
  referenceSourceTitles: string[];
  egfr: string | undefined;
  userEmployeeProfileIDNumber: DynamicComponents.Schemas.UserEmployeeProfile['idNumber'];
  userEmployeeProfileFullName: DynamicComponents.Schemas.UserEmployeeProfile['fullName'];
}

export interface IDashboardSessionsKPIs
  extends Pick<
    FixServerObject<DynamicComponents.Schemas.ConsultSession, 'id'>,
    | 'id'
    | 'patientAgeYears'
    | 'patientAgeMonths'
    | 'lockSession'
    | 'userEmployeeProfileID'
    | 'consultRequestTypeID'
    | 'createdDate'
  > {
  employee: string;
  genderTitle: string;
  consultRequestTypeTitle: string;
  insuranceStatus: Pick<DynamicComponents.Schemas.InsuranceStatus, 'id' | 'title'>;
  referralType: Pick<DynamicComponents.Schemas.ReferralType, 'id' | 'title'>;
  consultationResult: Pick<
    DynamicComponents.Schemas.ConsultSession,
    'answeredByPhone' | 'answeredByEmail' | 'answeredByOther'
  >;
  referenceSources: IReferenceSource[];
}

export interface IReferenceSource
  extends Pick<
    DynamicComponents.Schemas.ConsultSessionReferenceSource,
    'id' | 'referenceSourceID'
  > {
  title: DynamicComponents.Schemas.ReferenceSource['title'];
}

export interface IGridConsultSessionsFilters {
  search: string;
  medicationTitle: string;
  userEmployeeProfileIDs: [];
  consultRequestTypeID: string;
  patientName: string;
  patientIDNumber: string;
  date: [];
  fromAge: string;
  toAge: string;
}

export interface IGridConsultSessionsArgs extends IGridConsultSessionsFilters {
  order: DynamicOrder;
  take: number;
  skip: number;
  isRoleConsultant: string | boolean;
}

interface IConsultSessionClassification {
  consultSessionClassifications: DynamicComponents.Schemas.ConsultSessionClassification[];
}

export interface IConsultSession
  extends FixServerObject<DynamicComponents.Schemas.ConsultSession, 'id'> {
  consultRequestType: Pick<
    DynamicComponents.Schemas.ConsultRequestType,
    'disclaimerHtml' | 'disclaimerSubTitle' | 'disclaimerTitle' | 'title'
  >;
  consultSessionClassifications: IConsultSessionClassification[];
  userEmployeeProfileIDNumber: DynamicComponents.Schemas.UserEmployeeProfile['idNumber'];
  userEmployeeProfileFullName: DynamicComponents.Schemas.UserEmployeeProfile['fullName'];
}

export const schemaReferralRequest = yup.object({
  consultRequestTypeID: yup.string().required('rule-required').default(''),
  consultDisclaimerApproval: yup
    .boolean()
    .default(false)
    .required('rule-required')
    .test(
      'at-least-one-required',
      'Either consultDisclaimerApproval or consentConfirmationByEmail is required.',
      function () {
        const consentConfirmationByEmail = this.parent.consentConfirmationByEmail;
        return this.parent.consultDisclaimerApproval || consentConfirmationByEmail;
      },
    ),
  consentConfirmationByEmail: yup
    .boolean()
    .default(false)
    .required('rule-required')
    .test(
      'at-least-one-required',
      'Either consultDisclaimerApproval or consentConfirmationByEmail is required.',
      function () {
        const consultDisclaimerApproval = this.parent.consultDisclaimerApproval;
        return this.parent.consentConfirmationByEmail || consultDisclaimerApproval;
      },
    ),
});

export class ConsultSession implements yup.InferType<typeof schemaReferralRequest> {
  id!: string;
  consentConfirmationByEmail = false;
  consultDisclaimerApproval = false;
  consultRequestTypeID = '';
  userEmployeeProfileID = '';
  stepIndex = 1;
  egfr = '0';
  entryDate = new Date().toISOString();
  consultationDate = new Date().toISOString();
  lockSession = false;
  patientPdf = '';
  medicalStaffPdf = '';
}

const baseSchema = yup.object({
  patientIDNumber: yup
    .string()
    .required('rule-required')
    .default('')
    .min(8, 'rule-min-length')
    .max(10, 'rule-max-length'),
  patientName: yup.string().required('rule-required').default(''),
  genderID: yup.string().required('rule-required').default(''),
  genderOther: yup.string().default(''),
  consultationDate: yup.date().required('rule-required').default(new Date()),
  patientAgeMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .default(null)
    .test('patientAgeMonths', 'rule-required', function () {
      const { patientAgeMonths, patientAgeYears } = this.parent;
      return !!patientAgeMonths || !!patientAgeYears;
    }),
  patientAgeYears: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .default(null)
    .test('patientAgeYears', 'rule-required', function () {
      const { patientAgeMonths, patientAgeYears } = this.parent;
      return !!patientAgeMonths || !!patientAgeYears;
    }),
  patientEmail: yup.string().email('rule-email').default(''),
  patientPhone: yup
    .string()
    .required('rule-required')
    .default('')
    .min(9, 'rule-min-length')
    .max(11, 'rule-max-length'),
  referralReason: yup.string().default('').required('rule-required'),
  knownAllergies: yup.string().default(''),
});

export const schemaGeneralInformationPatient = baseSchema.shape({
  insuranceStatusID: yup.string().required('rule-required').default(''),
  insuranceStatusOther: yup.string().default(''),
  referralTypeID: yup.string().required('rule-required').default(''),
  referralTypeOther: yup.string().default(''),
});

export class GeneralInformationPatient
  implements yup.InferType<typeof schemaGeneralInformationPatient>
{
  id!: string;
  patientIDNumber = '';
  patientName = '';
  genderID = '';
  genderOther = '';
  consultationDate = new Date();
  patientAgeMonths = 0;
  patientAgeYears = 0;
  patientEmail = '';
  patientPhone = '';
  insuranceStatusID = '';
  insuranceStatusOther = '';
  referralTypeID = '';
  referralTypeOther = '';
  referralReason = '';
  knownAllergies = '';
  userEmployeeProfileID = '';
}

export const schemaGeneralInformationMedicalStaff = baseSchema.shape({
  medicalStaffName: yup.string().required('rule-required').default(''),
  medicalStaffEmail: yup.string().default(''),
  medicalStaffPhone: yup
    .string()
    .required('rule-required')
    .default('')
    .min(9, 'rule-min-length')
    .max(11, 'rule-max-length'),
  medicalStaffOptionID: yup.string().default('').required('rule-required').default(''),
  medicalStaffOptionOther: yup.string().default(''),
  medicalStaffWorkPlaceIDs: yup.array().of(yup.string()).default([]).min(1, 'rule-min-length'),
  medicalStaffWorkPlaceOther: yup.string().default(''),
});

export class GeneralInformationMedicalStaff
  implements yup.InferType<typeof schemaGeneralInformationMedicalStaff>
{
  id!: string;
  patientIDNumber = '';
  patientName = '';
  genderID = '';
  genderOther = '';
  consultationDate = new Date();
  patientAgeMonths = 0;
  patientAgeYears = 0;
  patientEmail = '';
  patientPhone = '';
  medicalStaffName = '';
  medicalStaffEmail = '';
  medicalStaffPhone = '';
  medicalStaffOptionID = '';
  medicalStaffOptionOther = '';
  medicalStaffWorkPlaceIDs: string[] = [];
  medicalStaffWorkPlaceOther = '';
  referralReason = '';
  knownAllergies = '';
  userEmployeeProfileID = '';
}

export const schemaEgfr = yup.object({
  patientWeight: yup.number().default(undefined),
  patientCreatinine: yup.number().default(undefined),
  egfrOption: yup.string().default(''),
});

export class Egfr implements yup.InferType<typeof schemaEgfr> {
  id!: string;
  patientWeight = 1;
  patientCreatinine = 1;
  egfrOption = '';
}

export const schemaConsultationResult = yup.object({
  answer: yup.string().required('rule-required').default(''),
  answeredByPhone: yup
    .boolean()
    .default(false)
    .test('answeredByPhone', 'rule-required', function () {
      const { answeredByPhone, answeredByEmail, isAnsweredByOther } = this.parent;
      return !!answeredByPhone || !!answeredByEmail || !!isAnsweredByOther;
    }),
  answeredByEmail: yup
    .boolean()
    .default(false)
    .test('answeredByEmail', 'rule-required', function () {
      const { answeredByPhone, answeredByEmail, isAnsweredByOther } = this.parent;
      return !!answeredByPhone || !!answeredByEmail || !!isAnsweredByOther;
    }),
  isAnsweredByOther: yup
    .boolean()
    .default(false)
    .test('isAnsweredByOther', 'rule-required', function () {
      const { answeredByPhone, answeredByEmail, isAnsweredByOther } = this.parent;
      return !!answeredByPhone || !!answeredByEmail || !!isAnsweredByOther;
    }),
  answeredByOther: yup
    .string()
    .default('')
    .test('answeredByOther', 'rule-required', function () {
      const { isAnsweredByOther, answeredByOther } = this.parent;
      return isAnsweredByOther ? answeredByOther : true;
    }),
  referenceSourceIDs: yup.array().of(yup.string()).default([]).min(1, 'rule-min-length'),
  referenceSourceOther: yup.string().default(''),
});

export class ConsultationResult implements yup.InferType<typeof schemaConsultationResult> {
  id!: string;
  answeredByPhone = false;
  answeredByEmail = false;
  isAnsweredByOther = false;
  answeredByOther = '';
  answer = '';
  referenceSourceIDs = [];
  referenceSourceOther = '';
}

export const schemaMedicationClassificationsTabs = yup.object({
  rightsExtractionClassificationIDs: yup.array().of(yup.string()).default([]),
  clinicalItemClassificationIDs: yup.array().of(yup.string()).default([]),
  logisticClassificationIDs: yup.array().of(yup.string()).default([]),
  classificationIDs: yup.array().of(yup.string()).default([]),
  other: yup.string().default(''),
  id: yup.string().default(''),
  isActive: yup.boolean().default(false),
});

const schemaMedicationClassificationsTabsModel = schemaMedicationClassificationsTabs.pick([
  'rightsExtractionClassificationIDs',
  'clinicalItemClassificationIDs',
  'logisticClassificationIDs',
  'other',
  'classificationIDs',
  'id',
  'isActive',
]);

export const schemaMedicationClassifications = yup.object({
  medicationClassificationsTabs: yup
    .array()
    .of(schemaMedicationClassificationsTabsModel)
    .default([]),
});

export class MedicationClassification
  implements yup.InferType<typeof schemaMedicationClassifications>
{
  id!: string;
  medicationClassificationsTabs = [];
}

export class MedicationClassificationsTabs
  implements yup.InferType<typeof schemaMedicationClassificationsTabs>
{
  id = '';
  classificationIDs = [];
  logisticClassificationIDs = [];
  rightsExtractionClassificationIDs = [];
  clinicalItemClassificationIDs = [];
  other = '';
  isActive = false;
}

import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_MEMBER_TYPES, IGridMemberType, IMemberType } from './models';

const REVALIDATE_KEY = 'MemberTypes' as const;

export * from './models';

export interface IGridMemberTypesParams {
  search: string;
  active: boolean | null;
  order: dynamic.DynamicOrder;
  take: number;
  skip: number;
}
type ApiModel = IMemberType;

class Service extends DynamicService<ApiModel> {}



export const ServiceMemberTypes = new Service({
  getAll: API_MEMBER_TYPES.GET_ALL_DYNAMIC,
  post: API_MEMBER_TYPES.POST,
  patch: API_MEMBER_TYPES.PATCH,
  delete: API_MEMBER_TYPES.DELETE,
});

export const apiMemberTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridMemberTypes: build.query<
      DynamicResult<IGridMemberType, { count: true }>,
      IGridMemberTypesParams
    >({
      queryFn: async ({ search, order, take, skip, active }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title'], search, dynamic.contains),
                dynamic.makeFilter(
                  'isActive',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'rowIndex',
              'title',
              'userPatientProfiles.count() as numberOfPharmacists',
              'isActive',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceMemberTypes.getAllDynamic<IGridMemberType, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getMemberType: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const { data } = await ServiceMemberTypes.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getMemberTypesOption: build.query<IGridMemberType[], void>({
      query: () => ({
        url: API_MEMBER_TYPES.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(dynamic.makeFilter(['isActive'], true, dynamic.equals))
            .join('&&'),
          select: dynamic.select('id', 'title'),
          orderBy: 'title',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postMemberType: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceMemberTypes.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchMemberType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceMemberTypes.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteMemberType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceMemberTypes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});

import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface Props extends TypographyProps {
  children: React.ReactNode;
}

export const HeaderTitle: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Typography variant={'h3'} component={'h1'} noWrap {...rest}>
      {children}
    </Typography>
  );
};

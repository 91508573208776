import * as dynamic from 'utils/dynamic';
import {
  apiRtk,
  behaviourMoveRows,
  decoratorRank,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_MEDICAL_STAFF_OPTIONS,
  IGridMedicalStaffOption,
  IMedicalStaffOption,
} from './models';

const REVALIDATE_KEY = 'MedicalStaffOptions' as const;

export * from './models';

type ApiModel = IMedicalStaffOption;

export interface IGridMedicalStaffOptionsParams {
  search: string;
  active: boolean | null;
  order: dynamic.DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<ApiModel> {
  @decoratorRank('rank')
  async post(data: Omit<ApiModel, 'id'>) {
    return super.post(data);
  }
  moveRows = async function (
    newRows: Partial<ApiModel>[],
    oldRows: Partial<ApiModel>[],
  ) {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: ServiceMedicalStaffOption.patch,
    });
  };
}

type SourceModel = Pick<ApiModel, 'id' | 'title' | 'isOther'>;

export const ServiceMedicalStaffOption = new Service({
  getAll: API_MEDICAL_STAFF_OPTIONS.GET_ALL_DYNAMIC,
  post: API_MEDICAL_STAFF_OPTIONS.POST,
  patch: API_MEDICAL_STAFF_OPTIONS.PATCH,
  delete: API_MEDICAL_STAFF_OPTIONS.DELETE,
});

export const apiMedicalStaffOptions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridMedicalStaffOptions: build.query<
      DynamicResult<IGridMedicalStaffOption, { count: true }>,
      IGridMedicalStaffOptionsParams
    >({
      queryFn: async ({ search, active, order, take, skip }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title'], search, dynamic.contains),
                dynamic.makeFilter(
                  'isActive',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'rank', 'isOther', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceMedicalStaffOption.getAllDynamic<
            IGridMedicalStaffOption,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getMedicalStaffOption: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive', 'isOther', 'rank'),
          };
          const { data } = await ServiceMedicalStaffOption.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getSourceMedicalStaffOptions: build.query<SourceModel[], void>({
      query: () => ({
        url: API_MEDICAL_STAFF_OPTIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title', 'isOther'),
          filter: dynamic
            .mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals))
            .join('&&'),
          orderBy: 'isOther asc, rank asc',
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    postMedicalStaffOption: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceMedicalStaffOption.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchMedicalStaffOption: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceMedicalStaffOption.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteMedicalStaffOption: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceMedicalStaffOption.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    moveMedicalStaffOption: build.mutation<
      void,
      { newRows: Partial<ApiModel>[]; oldRows: Partial<ApiModel>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceMedicalStaffOption.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id })),
      ],
    }),
  }),
});

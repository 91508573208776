import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_PAYMENT_ACCOUNTS,
  IDashboardPaymentAccounts,
  IDashboardPaymentAccountsArgs,
  IGridPaymentAccounts,
  IPaymentAccount,
} from './models';

const REVALIDATE_KEY = 'PaymentAccounts' as const;

export * from './models';
type ApiModel = IPaymentAccount;
export interface IGridPaymentAccountsParams {
  search: string;
  active: boolean | null;
  order: dynamic.DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<ApiModel> {}



export const ServicePaymentAccounts = new Service({
  getAll: API_PAYMENT_ACCOUNTS.GET_ALL_DYNAMIC,
  post: API_PAYMENT_ACCOUNTS.POST,
  patch: API_PAYMENT_ACCOUNTS.PATCH,
  delete: API_PAYMENT_ACCOUNTS.DELETE,
});

type SourceModel = Pick<ApiModel, 'id' | 'title' | 'accountCode'>;

export const apiPaymentAccounts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourcePaymentAccounts: build.query<SourceModel[], void>({
      query: () => ({
        url: API_PAYMENT_ACCOUNTS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(dynamic.makeFilter(['isActive'], true, dynamic.equals))
            .join('&&'),
          select: dynamic.select('id', 'title', 'accountCode'),
          orderBy: dynamic.orderBy('title', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getGridPaymentAccounts: build.query<
      DynamicResult<IGridPaymentAccounts, { count: true }>,
      IGridPaymentAccountsParams
    >({
      queryFn: async ({ search, active, order, take, skip }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('title', search, dynamic.contains),
                dynamic.makeFilter(
                  'isActive',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'accountCode', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServicePaymentAccounts.getAllDynamic<
            IGridPaymentAccounts,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getDashboardPaymentAccounts: build.query<
      DynamicResult<IDashboardPaymentAccounts>,
      IDashboardPaymentAccountsArgs
    >({
      queryFn: async ({ search, orderBy }) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'accountCode'),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  ['title', 'accountCode'],
                  search,
                  dynamic.decoratorIsNotNullable(dynamic.contains),
                ),
                dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
          };
          const { data } = await ServicePaymentAccounts.getAllDynamic<
            IDashboardPaymentAccounts,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getPaymentAccount: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'accountCode', 'isActive'),
          };
          const { data } = await ServicePaymentAccounts.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postPaymentAccount: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentAccounts.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchPaymentAccount: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentAccounts.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deletePaymentAccount: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentAccounts.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});

import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Suppliers';

export const API_SUPPLIERS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  DELETE: (data: Pick<ISupplier, 'id'>) => `${API}/Delete/${data.id}`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ISupplier, 'id'>) => `${API}/Patch/${data.id}`,
};

export interface ISupplier extends FixServerObject<DynamicComponents.Schemas.Supplier, 'id'> {}

export interface ISupplierInvoice {
  firstInvoice: string;
  lastInvoice: string;
  numberOfInvoices: number;
}

export const schemaSuppliers = yup.object({
  email: yup.string().email('rule-email').trim().default(''),
  companyName: yup.string().required('rule-required').trim().default(''),
  companyNumber: yup.string().required('rule-required').trim().default(''),
  isActive: yup.boolean().default(true),
  bankAccountDetails: yup.string().notRequired().trim().default(''),
  phone1: yup.string().notRequired().trim().default(''),
  phone2: yup.string().notRequired().trim().default(''),
  phoneMobile: yup.string().notRequired().trim().default(''),
  address: yup.string().notRequired().trim().default(''),
});

export interface IGridSupplier
  extends Pick<
    ISupplier,
    | 'id'
    | 'companyName'
    | 'companyNumber'
    | 'phone1'
    | 'phone2'
    | 'phoneMobile'
    | 'email'
    | 'isActive'
  > {
  numberOfInvoices: number;
}

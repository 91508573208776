import { ICustomer } from 'services/customers';
import * as dynamic from 'utils/dynamic';

export const NAMESPACE = 'CUSTOMERS';
export const SELECT = dynamic.select(
  'id',
  'companyNumber',
  'companyName',
  'companyLogo',
  'isActive',
  'email',
  'phone1',
  'phone2',
  'address',
  'remarks',
  'customerTypeID',
  'customerType.title as customerTypeTitle',
);

export interface IGridCustomers
  extends Pick<
    ICustomer,
    | 'id'
    | 'companyName'
    | 'companyNumber'
    | 'companyLogo'
    | 'isActive'
    | 'email'
    | 'phone1'
    | 'phone2'
    | 'address'
    | 'remarks'
    | 'customerTypeID'
  > {
  customerTypeTitle: string;
}

export enum BlockedInvoiceReason {
  DUPLICATE = 'duplicate',
  RECEIPT = 'receipt',
  CONVERT = 'convert',
  IMPORT_EXCEL = 'import-excel',
}

export type BlockedInvoice = {
  reason: BlockedInvoiceReason;
  startTimestamp: number;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionAccountLogout } from 'store/auth';
import { DynamicOrder } from 'utils/dynamic';
import { actionCustomersGetAllDynamic } from './actions';
import { BlockedInvoice, BlockedInvoiceReason, IGridCustomers, NAMESPACE } from './helpers';

interface Filters {
  search: string;
  companyNumber: string;
  customerTypeIDs: string[];
  active: boolean | null;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridCustomers[];
  filters: Filters;
  pagination: Pagination;
  orderBy: DynamicOrder;
  dataProfileInvoiceDetailsLength: number;
  blockedInvoices: Record<string, BlockedInvoice>;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    dataProfileInvoiceDetailsLength: 0,
    isInit: false,
    data: [],
    filters: {
      search: '',
      companyNumber: '',
      customerTypeIDs: [],
      active: true,
    },
    pagination: {
      take: 100,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'companyName',
      order: 'asc',
    },
    blockedInvoices: {},
  };
};

const slice = createSlice({
  name: NAMESPACE,
  initialState: initState(),
  reducers: {
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    orderBySet(state, action: PayloadAction<Partial<DynamicOrder>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    detailsLengthSet(state, action: PayloadAction<number>) {
      state.dataProfileInvoiceDetailsLength = action.payload;
    },
    addInvoiceBlocker(
      state,
      action: PayloadAction<{ invoiceID: string; reason: BlockedInvoiceReason }>,
    ) {
      state.blockedInvoices[action.payload.invoiceID] = {
        reason: action.payload.reason,
        startTimestamp: new Date().getTime(),
      };
    },
    removeInvoiceBlocker(state, action: PayloadAction<string>) {
      delete state.blockedInvoices[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCustomersGetAllDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionCustomersGetAllDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value;
        state.pagination.count = count;
      })
      .addCase(actionCustomersGetAllDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

export const actionsCustomers = slice.actions;
export const reducerCustomers = slice.reducer;

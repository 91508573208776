import { Box } from '@mui/material';
import { HeaderPortal } from 'components/header-portal';
import { HeaderTitle } from 'components/header-title';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const ConsultedFormSettingsLayout = memo(() => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.CONSULTED_FORM_SETTINGS.path,
        to: APP_ROUTES.CONSULTED_FORM_SETTINGS().config,
        label: tp('consult-request-types'),
      },
      {
        value: APP_ROUTES.GENDERS.path,
        to: APP_ROUTES.GENDERS().config,
        label: tp('genders'),
      },
      {
        value: APP_ROUTES.INSURANCE_STATUSES.path,
        to: APP_ROUTES.INSURANCE_STATUSES().config,
        label: tp('insurance-statuses'),
      },
      {
        value: APP_ROUTES.REFERRAL_TYPES.path,
        to: APP_ROUTES.REFERRAL_TYPES().config,
        label: tp('referral-types'),
      },
      {
        value: APP_ROUTES.MEDICAL_STAFF_OPTIONS.path,
        to: APP_ROUTES.MEDICAL_STAFF_OPTIONS().config,
        label: tp('medical-staff-options'),
      },
      {
        value: APP_ROUTES.MEDICAL_STAFF_WORK_PLACES.path,
        to: APP_ROUTES.MEDICAL_STAFF_WORK_PLACES().config,
        label: tp('medical-staff-work-places'),
      },
      {
        value: APP_ROUTES.CLINICAL_CLASSIFICATIONS_SUBJECTS.path,
        to: APP_ROUTES.CLINICAL_CLASSIFICATIONS_SUBJECTS().config,
        label: tp('clinical-subject-classifications'),
      },

      {
        value: APP_ROUTES.LOGISTIC_CLASSIFICATIONS.path,
        to: APP_ROUTES.LOGISTIC_CLASSIFICATIONS().config,
        label: tp('logistic-classifications'),
      },
      {
        value: APP_ROUTES.RIGHTS_EXTRACTION_CLASSIFICATIONS.path,
        to: APP_ROUTES.RIGHTS_EXTRACTION_CLASSIFICATIONS().config,
        label: tp('rights-extraction-classifications'),
      },
      {
        value: APP_ROUTES.RESPONSIVENESS_TO_TREATMENTS.path,
        to: APP_ROUTES.RESPONSIVENESS_TO_TREATMENTS().config,
        label: tp('responsiveness-to-treatments'),
      },
      {
        value: APP_ROUTES.REFERENCE_SOURCES.path,
        to: APP_ROUTES.REFERENCE_SOURCES().config,
        label: tp('reference-sources'),
      },
    ];
  }, [tp]);
  return (
    <>
      <HeaderPortal>
        <HeaderTitle children={tp('consulted-form-settings')} />
      </HeaderPortal>
      <Box className={style.inner}>
        <TabsPage tabs={tabs} />
      </Box>
    </>
  );
});

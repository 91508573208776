import { createAsyncThunk } from '@reduxjs/toolkit';
import { eventSessionStatusValueCreator } from 'components/event-session-status';
import { ServiceEventSessions } from 'services/event-sessions';
import { AppAsyncThunkConfig } from 'store';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { IGridEventSession, NAMESPACE, SELECT } from './helpers';
import {
  selectEventSessionsFilters,
  selectEventSessionsOrderBy,
  selectEventSessionsPagination,
} from './selectors';

const makeFilters = (filters: ReturnType<typeof selectEventSessionsFilters>) => {
  const { active, startRange, status, search, eventSessionCategoryIDs } = filters;
  return dynamic
    .mergeFilters(
      dynamic.makeFilter('isActive', active, dynamic.decoratorIsNotNullable(dynamic.equals)),
      dynamic.makeFilter('eventSessionCategoryID', eventSessionCategoryIDs, dynamic.equalsSome),
      dynamic.makeFilter('startDate', startRange, dynamic.dateRange),

      dynamic.makeFilter('', status, eventSessionStatusValueCreator),

      dynamic.makeFilter(
        ['companyName', 'email', 'phone1', 'phone2', 'address', 'remarks'],
        search,
        dynamic.contains,
      ),
    )
    .join('&&');
};

export const actionEventSessionsGetAllDynamic = createAsyncThunk<
  { value: IGridEventSession[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionEventSessionsGetAllDynamic`, async (_, { getState }) => {
  try {
    const filters = selectEventSessionsFilters(getState());
    const { take, skip } = selectEventSessionsPagination(getState());
    const { field, order } = selectEventSessionsOrderBy(getState());

    const params = {
      filter: makeFilters(filters),
      select: SELECT,
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceEventSessions.getAllDynamic<
      IGridEventSession,
      typeof params
    >(params);

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionEventSessionsGetExportData = createAsyncThunk<
  IGridEventSession[],
  { ignorePagination: boolean },
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionEventSessionsGetExportData`, async ({ ignorePagination }, { getState }) => {
  try {
    const filters = selectEventSessionsFilters(getState());
    const { take, skip } = selectEventSessionsPagination(getState());
    const { field, order } = selectEventSessionsOrderBy(getState());

    const params = {
      filter: makeFilters(filters),
      select: SELECT,
      orderBy: dynamic.orderBy(field, order),
      count: false,
      take: ignorePagination ? undefined : take,
      skip: ignorePagination ? undefined : skip,
    };
    const { data: result } = await ServiceEventSessions.getAllDynamic<
      IGridEventSession,
      typeof params
    >(params);

    return result.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

import * as dynamic from 'utils/dynamic';
import {
  apiRtk,
  decoratorWithFiles,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_ARTICLE_SUBJECTS,
  ArticleSourceModel,
  IArticleSubject,
  IGridArticleSubject,
  IGridArticleSubjectInput,
} from './models';

const REVALIDATE_KEY = 'ArticleSubjects' as const;

export * from './models';

type ApiModel = IArticleSubject;

class Service extends DynamicService<ApiModel> {
  @decoratorWithFiles('id', 'banner')
  async post(data: Partial<ApiModel>) {
    return super.post(data);
  }

  @decoratorWithFiles('id', 'banner')
  async patch(data: Partial<ApiModel>) {
    return super.patch(data);
  }
}

export const ServiceArticleSubjects = new Service({
  mainField: 'id',
  getAll: API_ARTICLE_SUBJECTS.GET_ALL_DYNAMIC,
  post: API_ARTICLE_SUBJECTS.POST,
  patch: API_ARTICLE_SUBJECTS.PATCH,
  delete: API_ARTICLE_SUBJECTS.DELETE,
});

export const apiArticleSubjects = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceArticleSubject: build.query<Array<ArticleSourceModel>, void>({
      queryFn: async () => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const response = await ServiceArticleSubjects.getAllDynamic(params);
          const data = transformResponseDynamic(response.data);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY }],
    }),
    getGridArticleSubjects: build.query<
      DynamicResult<IGridArticleSubject, { count: true }>,
      IGridArticleSubjectInput
    >({
      queryFn: async ({ search, order, take, skip, active }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('title', search, dynamic.contains),
                dynamic.makeFilter(
                  'isActive',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceArticleSubjects.getAllDynamic<
            IGridArticleSubject,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getArticleSubject: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceArticleSubjects.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postArticleSubject: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceArticleSubjects.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchArticleSubject: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceArticleSubjects.patch({
            ...data,
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(id) },
      ],
    }),
    deleteArticleSubject: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceArticleSubjects.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(id) },
      ],
    }),
  }),
});

import { add } from 'date-fns';
import { convertToDate } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import { apiAppBackend, apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { InferType } from 'yup';
import { apiInvoiceTypes, InvoiceStep } from '../invoice-types';
import { ServiceMediaUploads } from '../media-uploads';
import {
  API_CUSTOMER_INVOICES,
  ConvertCustomerInvoiceInput,
  CreateReceiptInput,
  CustomerInvoice,
  CustomerInvoiceExtended,
  GridCustomerInvoice,
  GridCustomerInvoiceInput,
  schemaCustomerInvoiceImportFromExcel,
  SendCustomerInvoiceInput,
} from './models';
import { SELECT_CUSTOMER_GRID, SELECT_CUSTOMER_INVOICE_EXTENDED } from './queries';
import { isFileLike } from 'utils/file-uploader';

const REVALIDATE_TAG = 'CustomerInvoices' as const;

export * from './models';
type ApiModel = CustomerInvoice;
class Service extends DynamicService<ApiModel> {
  convertTo = async (input: BackendComponents.Schemas.CustomerInvoiceConvertInvoiceDto) => {
    return apiAppBackend.post<BackendComponents.Schemas.CustomerInvoiceConvertDtoOutput>(
      API_CUSTOMER_INVOICES.CONVERT_TO,
      input,
    );
  };
  createReceipt = async (input: BackendComponents.Schemas.CustomerInvoiceReceiptDto) => {
    const { data } =
      await apiAppBackend.post<BackendComponents.Schemas.CustomerInvoiceReceiptDtoOutput>(
        API_CUSTOMER_INVOICES.RECEIPT,
        input,
      );

    return data;
  };

  duplicate = async (invoiceID: string) => {
    return apiAppBackend.post<BackendComponents.Schemas.CustomerInvoiceDuplicateDtoOutput>(
      API_CUSTOMER_INVOICES.DUPLICATE(invoiceID),
    );
  };
  validateExcel = async (url: string) => {
    return this.engine.get(API_CUSTOMER_INVOICES.VALIDATE_EXCEL, { params: { excelUrl: url } });
  };
  bulkInvoiceDetails = async (input: DynamicComponents.Schemas.BulkInvoiceDetailRequest) => {
    return this.engine.post(API_CUSTOMER_INVOICES.UPLOAD_BULK_INVOICE_DETAILS, input);
  };
}

export const ServiceCustomerInvoices = new Service({
  getAll: API_CUSTOMER_INVOICES.GET_ALL_DYNAMIC,
  post: API_CUSTOMER_INVOICES.POST,
  patch: API_CUSTOMER_INVOICES.PATCH,
  delete: API_CUSTOMER_INVOICES.DELETE,
});

export const apiCustomerInvoices = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridCustomerInvoices: build.query<
      DynamicResult<GridCustomerInvoice, { count: true }>,
      GridCustomerInvoiceInput
    >({
      queryFn: async ({ skip, take, customerID, search, orderBy, active }) => {
        try {
          const params = {
            select: SELECT_CUSTOMER_GRID,
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'ignore',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.notEquals),
                ),
                dynamic.makeFilter(
                  'invoiceNumber',
                  search,
                  dynamic.decoratorIsNotNullable(dynamic.contains),
                ),
                dynamic.makeFilter('customerID', customerID, dynamic.equals),
              )
              .join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            skip,
            take,
          };
          const { data } = await ServiceCustomerInvoices.getAllDynamic<
            GridCustomerInvoice,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getCustomerInvoiceExtended: build.query<CustomerInvoiceExtended, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: SELECT_CUSTOMER_INVOICE_EXTENDED,
          };
          const { data } = await ServiceCustomerInvoices.getDynamic<CustomerInvoiceExtended>(
            id,
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    sendCustomerInvoice: build.mutation<void, SendCustomerInvoiceInput>({
      query: (data) => ({
        url: API_CUSTOMER_INVOICES.SEND_EMAIL,
        method: 'post',
        data,
      }),
    }),
    createCustomerInvoiceReceipt: build.mutation<
      { id: string },
      Omit<CreateReceiptInput, 'invoiceTypeID'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const requestInvoiceType = dispatch(
            apiInvoiceTypes.endpoints.getInvoiceTypesOption.initiate(),
          );
          requestInvoiceType.unsubscribe();

          const invoiceTypes = await requestInvoiceType.unwrap();

          const invoiceType = invoiceTypes.find(({ step }) => step === InvoiceStep.COMPLETE);

          if (!invoiceType) {
            return { error: new Error('invoiceTypeID is required') };
          }

          const result = await ServiceCustomerInvoices.createReceipt({
            ...input,
            invoiceTypeID: invoiceType.id,
          });

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    duplicateCustomerInvoice: build.mutation<{ id: string }, string>({
      queryFn: async (invoiceID) => {
        try {
          const { data } = await ServiceCustomerInvoices.duplicate(invoiceID);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    convertCustomerInvoice: build.mutation<{ id: string }, ConvertCustomerInvoiceInput>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceCustomerInvoices.convertTo(input);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    postCustomerInvoice: build.mutation<ApiModel, Omit<ApiModel, 'id'>>({
      queryFn: async (input) => {
        try {
          return await ServiceCustomerInvoices.post(input);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchCustomerInvoice: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (input) => {
        try {
          await ServiceCustomerInvoices.patch(input);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    deleteCustomerInvoice: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (input) => {
        try {
          await ServiceCustomerInvoices.delete(input);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    importFromExcelCustomerInvoice: build.mutation<
      void,
      InferType<typeof schemaCustomerInvoiceImportFromExcel>
    >({
      queryFn: async (input) => {
        try {
          const { file, fromDate, toDate } = input;

          const originalDateFromDate = convertToDate(fromDate);
          const originalDateToDate = convertToDate(toDate);

          const timeZoneOffsetInMinutes = Math.abs(new Date().getTimezoneOffset());

          const adjustedFromDate = add(originalDateFromDate, { minutes: timeZoneOffsetInMinutes });
          const adjustedToDate = add(originalDateToDate, { minutes: timeZoneOffsetInMinutes });

          if (!isFileLike(file)) {
            return { error: new Error('file is required') };
          }

          const {
            data: { filePath },
          } = await ServiceMediaUploads.uploadFile(file);

          await ServiceCustomerInvoices.validateExcel(filePath);

          await ServiceCustomerInvoices.bulkInvoiceDetails({
            invoiceID: input.customerInvoiceID,
            excelUrl: filePath,
            paymentTypeID: input.paymentTypeID,
            incomeAccountID: input.incomeAccountID,
            duration: String(input.duration),
            quantity: input.quantity,
            amount: input.amount,
            fromDate: adjustedFromDate.toISOString(),
            toDate: adjustedToDate.toISOString(),
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiEventSessionCategories } from './api';

const selectResult = apiEventSessionCategories.endpoints.getSourceEventSessionCategories.select();
const selectSource = createSelector(selectResult, (res) => {
  return res.data || [];
});
export const selectEventSessionCategoriesMap = createSelector(selectSource, (data) => {
  return keyBy(data, 'id');
});

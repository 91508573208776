import { ActionMatchingPattern } from '@redux-saga/types';
import { apiUserEmployeeProfiles } from 'services/user-employee-profiles';
import { actionAccountGetUser } from 'store/auth/actions';
import { actionUserEmployeesRevalidateData } from 'store/user-employees';
import { debounce, put, select } from 'typed-redux-saga';
import { selectAccountUser } from './selectors';

function* watcherAccountChange(
  action: ActionMatchingPattern<
    typeof apiUserEmployeeProfiles.endpoints.patchUserCrmProfile.matchFulfilled
  >,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  yield* put(actionUserEmployeesRevalidateData());

  const user = yield* select(selectAccountUser);

  if (!user) return;

  const isCurrent = user.appUserID === originalArgs.appIdentityUserID;

  if (!isCurrent) return;

  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
}
export const sagasAccounts = [
  debounce(
    5000,
    apiUserEmployeeProfiles.endpoints.patchUserCrmProfile.matchFulfilled,
    watcherAccountChange,
  ),
];

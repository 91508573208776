import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserEmployeeProfile } from 'services/user-employee-profiles';
import { actionUserEmployeesChangeItem, actionUserEmployeesGetAllDynamic } from './actions';

interface Filters {
  search: string;
  userEmployeeProfilePermissionID: string;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}
export interface IGridUserEmployee
  extends Pick<
    UserEmployeeProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'firstName'
    | 'lastName'
    | 'userEmployeeProfilePermissionID'
    | 'email'
    | 'mobilePhone'
    | 'isActive'
  > {
  position: string;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridUserEmployee[];
  filters: Filters;
  pagination: Pagination;
  orderBy: OrderBy;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    filters: {
      search: '',
      userEmployeeProfilePermissionID: '',
    },
    pagination: {
      take: 50,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'firstName',
      order: 'asc',
    },
  };
};

const slice = createSlice({
  name: 'USERS_EMPLOYEE',
  initialState: initState(),
  reducers: {
    actionUserEmployeesRevalidateData(state) {
      state.isInit = false;
    },
    actionUserEmployeesFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionUserEmployeesFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionUserEmployeesOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionUserEmployeesGetAllDynamic.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
      state.pagination.take = action.meta.arg?.take || state.pagination.take;
    });
    builder.addCase(actionUserEmployeesGetAllDynamic.fulfilled, (state, action) => {
      const {
        payload: { value, count },
      } = action;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
      state.pagination.count = count;
    });
    builder.addCase(actionUserEmployeesGetAllDynamic.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
    builder.addCase(actionUserEmployeesChangeItem.fulfilled, (state) => {
      state.isInit = false;
    });
  },
});

export const {
  actionUserEmployeesOrderBySet,
  actionUserEmployeesFiltersSet,
  actionUserEmployeesRevalidateData,
} = slice.actions;
export const reducerUserEmployees = slice.reducer;

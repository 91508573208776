import { endOfDay, startOfDay } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { apiRtk, decoratorWithFiles, DynamicService } from 'utils/service';
import {
  API_EVENT_SESSIONS,
  EventSessionPostInput,
  EventSessionUpdateInput,
  IAppEventSession,
  IEventSession,
  schemaEventSession,
} from './models';

const REVALIDATE_TAG = 'EventSessions' as const;

type ApiModel = IEventSession;

export * from './models';

class Service extends DynamicService<ApiModel> {
  @decoratorWithFiles('id', 'picture', 'banner')
  async post(data: Partial<ApiModel>) {
    return super.post(data);
  }

  @decoratorWithFiles('id', 'picture', 'banner')
  async patch(data: Partial<ApiModel>) {
    return super.patch(data);
  }
}

export const ServiceEventSessions = new Service({
  mainField: 'id',
  getAll: API_EVENT_SESSIONS.GET_ALL_DYNAMIC,
  post: API_EVENT_SESSIONS.POST,
  patch: API_EVENT_SESSIONS.PATCH,
  delete: API_EVENT_SESSIONS.DELETE,
});

export const apiEventSessions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getEventSession: build.query<IAppEventSession, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceEventSessions.getDynamic(id);

          const result = schemaEventSession.cast(data, {
            stripUnknown: true,
            assert: false,
          });

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    createEventSession: build.mutation<void, EventSessionPostInput>({
      queryFn: async (input) => {
        try {
          const { startDate, endDate, ...rest } = input;

          const payload = {
            ...rest,
            startDate: startOfDay(convertToDate(startDate)).toISOString(),
            endDate: endOfDay(convertToDate(endDate)).toISOString(),
          };

          await ServiceEventSessions.post(payload);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    updateEventSession: build.mutation<void, EventSessionUpdateInput>({
      queryFn: async (input) => {
        try {
          const { startDate, endDate, ...rest } = input;

          await ServiceEventSessions.patch({
            ...rest,
            startDate: startOfDay(convertToDate(startDate)).toISOString(),
            endDate: endOfDay(convertToDate(endDate)).toISOString(),
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteEventSession: build.mutation<void, Partial<ApiModel>>({
      queryFn: async (data) => {
        try {
          await ServiceEventSessions.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
  }),
});

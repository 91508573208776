import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'InvoiceTypes';

export const API_INVOICE_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IInvoiceType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IInvoiceType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IInvoiceType
  extends FixServerObject<
    DynamicComponents.Schemas.InvoiceType,
    'id' | 'rowIndex' | 'step' | 'title'
  > {
  step: InvoiceStep;
}

export const schemaInvoiceTypes = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
  externalAppKey: yup.number().required('rule-required').default(0),
  paymentRequired: yup.boolean().default(false)
});

export enum InvoiceStep {
  COMPLETE = 3,
}

export interface IGridInvoiceType
  extends Pick<
    IInvoiceType,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'externalAppKey' | 'paymentRequired' | 'rank'
  > {}

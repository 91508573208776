import { createSelector } from '@reduxjs/toolkit';
import { addSeconds } from 'date-fns';
import { TIME_CREATING_INVOICE_PAYMENT_SECONDS } from 'services/cardcom';
import { convertToDate } from 'utils/dates';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.userPharmacistProfiles;

export const selectUserPharmacistProfilesStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectUserPharmacistProfilesData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectUserPharmacistProfilesBlockedInvoices = createSelector(
  selectState,
  ({ blockedInvoices }) => {
    return blockedInvoices;
  },
);
export const selectUserPharmacistProfilesBlockInvoices = createSelector(
  selectState,
  ({ blockInvoices }) => {
    return blockInvoices.map((item) => ({
      ...item,
      endDate: addSeconds(convertToDate(item.startDate), TIME_CREATING_INVOICE_PAYMENT_SECONDS),
    }));
  },
);
export const selectUserPharmacistProfilesFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectUserPharmacistProfilesPagination = createSelector(
  selectState,
  ({ pagination }) => {
    return calcPaginationState(pagination);
  },
);
export const selectUserPharmacistProfilesOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});

import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiCustomerTypes } from './api';

const selectResult = apiCustomerTypes.endpoints.getSourceCustomerTypes.select();
const selectSource = createSelector(selectResult, (res) => {
  return res.data || [];
});
export const selectCustomerTypesMap = createSelector(selectSource, (data) => {
  return keyBy(data, 'id');
});

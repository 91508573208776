import { MoreVert } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { forwardRef } from 'react';

interface Props extends IconButtonProps {}

export const ButtonThreeDots = forwardRef<HTMLButtonElement, Props>(({ ...rest }, ref) => {
  return (
    <IconButton ref={ref} {...rest}>
      <MoreVert />
    </IconButton>
  );
});

import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'NamePrefixes';

export const API_NAME_PREFIXES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<INamePrefix, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<INamePrefix, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface INamePrefix extends FixServerObject<DynamicComponents.Schemas.NamePrefix, 'id'> {}


export const schemaNamePrefixes = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export interface IGridNamePrefix
  extends Pick<INamePrefix, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}

import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';

const API = 'EventSessionCategories';

export const API_EVENT_SESSION_CATEGORIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IEventSessionCategory, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IEventSessionCategory, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IEventSessionCategory extends DynamicComponents.Schemas.EventSessionCategory {
  id: string;
}

export const schemaEventSessionCategory = yup.object({
  title: yup.string().required('rule-required').default(''),
  color: yup.string().required('rule-required').nullable().default(''),
  icon: yup.mixed<string>().required('rule-required').nullable().default(''),
  isActive: yup.boolean().default(true),
  isVirtual: yup.boolean().default(true),
});
export interface IGridEventSessionCategory
  extends Pick<
    IEventSessionCategory,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'icon' | 'color' | 'isVirtual'
  > {}

export interface IGetGridEventSessionCategoriesInput {
  search: string;
  active: boolean | null;
  take: number;
  skip: number;
  order: DynamicOrder;
}

import { apiAppDynamic, apiRtk } from 'utils/service';
import { API_MEDIA_PRIVATE } from './models';

export * from './models';

class Service {
  async getFile(filePath: string) {
    const result = await apiAppDynamic.get(API_MEDIA_PRIVATE.DOWNLOAD, {
      params: {
        fileName: filePath,
      },
      responseType: 'blob',
    });

    return URL.createObjectURL(result.data);
  }
}

export const ServiceMediaPrivate = new Service();

export const apiMediaPrivate = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getPrivateFile: build.query<string, string>({
      queryFn: async (filePath) => {
        try {
          const result = await ServiceMediaPrivate.getFile(filePath);
          return { data: result };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

import { IEventSession } from 'services/event-sessions';
import * as dynamic from 'utils/dynamic';

export const NAMESPACE = 'CUSTOMERS';
export const SELECT = dynamic.select(
  'id',
  'title',
  'subTitle',
  'startDate',
  'endDate',
  'eventSessionCategoryID',
  'isActive',
  'userPharmacistProfileEventSessions.Count() as totalUsers',
);

export interface IGridEventSession
  extends Pick<
    IEventSession,
    'id' | 'title' | 'subTitle' | 'startDate' | 'eventSessionCategoryID'
  > {
  totalUsers: number;
}

import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'WorkPlaces';

export const API_WORK_PLACES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<WorkPlace, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<WorkPlace, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IWorkPlace extends FixServerObject<DynamicComponents.Schemas.WorkPlace, 'id'> {}

export const schemaWorkPlaces = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export class WorkPlace {
  id!: string;
  title = '';
  isActive = true;
}

export interface IGridWorkPlace extends Pick<IWorkPlace, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}

import React from 'react';

import style from './index.module.scss';

import { Tab, TabProps, Tabs, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { Link, Outlet, useLocation } from 'react-router-dom';

interface ITab extends TabProps<typeof Link> {
  value: string;
}
interface Classes {
  root: string;
  wrap: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
  tabs: ITab[];
}

export const TabsPage: React.FC<Props> = ({ className, classes, tabs }) => {
  const { pathname } = useLocation();
  const isResponsive = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  return (
    <div className={clsx(style.root, classes?.root, className)}>
      <div className={style.panel}>
        <Tabs
          value={pathname}
          variant={'scrollable'}
          orientation={isResponsive ? 'horizontal' : 'vertical'}
          classes={{
            root: style.tabs,
            indicator: style.tabsIndicator,
            flexContainer: style.tabsFlexContainer,
          }}
        >
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.value}
                {...tab}
                component={Link}
                classes={{
                  root: style.tab,
                  selected: style.tabSelected,
                }}
              />
            );
          })}
        </Tabs>
      </div>

      <div className={clsx(style.wrap, classes?.wrap)}>
        <Outlet />
      </div>
    </div>
  );
};

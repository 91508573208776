import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial, Unset } from 'utils/types';
import {
  API_SESSIONS,
  ConsultSession,
  IConsultSession,
  IDashboardSessionsKPIs,
  IGridConsultSessions,
  IGridConsultSessionsArgs,
} from './models';

const REVALIDATE_KEY = 'ConsultSessions' as const;

export * from './models';

class Service extends DynamicService<ConsultSession> {}

type ApiModel = ConsultSession;

export const ServiceConsultSession = new Service({
  getAll: API_SESSIONS.GET_ALL_DYNAMIC,
  post: API_SESSIONS.POST,
  patch: API_SESSIONS.PATCH,
  delete: API_SESSIONS.DELETE,
});

export const apiConsultSessions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getDashboardSessionsKPIs: build.query<
      DynamicResult<IDashboardSessionsKPIs, { count: true }>,
      void
    >({
      queryFn: async () => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'createdDate',
              'lockSession',
              'patientAgeYears',
              'patientAgeMonths',
              'gender.title as genderTitle',
              '(userEmployeeProfile.firstName + " " + userEmployeeProfile.lastName).trim() as employee',
              'userEmployeeProfileID',
              'consultRequestTypeID',
              'consultRequestType.title as consultRequestTypeTitle',
              'new { insuranceStatus.id, insuranceStatus.title } as insuranceStatus',
              'new { referralType.id, referralType.title } as referralType',
              'consultSessionReferenceSources.Select(s => new { s.id, s.referenceSource.id as referenceSourceID, s.referenceSource.title }) as referenceSources',
              'new { answeredByPhone, answeredByEmail, answeredByOther } as consultationResult',
            ),
            count: true,
          };
          const { data } = await ServiceConsultSession.getAllDynamic<
            IDashboardSessionsKPIs,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridConsultSessions: build.query<
      DynamicResult<IGridConsultSessions, { count: true }>,
      IGridConsultSessionsArgs
    >({
      queryFn: async ({
        skip,
        take,
        order,
        search,
        medicationTitle,
        date,
        patientName,
        userEmployeeProfileIDs,
        consultRequestTypeID,
        patientIDNumber,
        isRoleConsultant,
        fromAge,
        toAge,
      }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  ['patientName', 'gender.title', 'patientName', 'patientIDNumber'],
                  search,
                  dynamic.decoratorIsNotNullable(dynamic.contains),
                ),
                medicationTitle
                  ? `consultSessionMedications.Any(m => ${dynamic.makeFilter(
                      'm.title',
                      medicationTitle,
                      dynamic.contains,
                    )})`
                  : null,
                dynamic.makeFilter(
                  'entryDate',
                  date,
                  dynamic.decoratorIsNotNullable(dynamic.dateRange),
                ),
                dynamic.makeFilter(
                  ['patientName'],
                  patientName,
                  dynamic.decoratorIsNotNullable(dynamic.contains),
                ),
                dynamic.makeFilter(
                  ['patientIDNumber'],
                  patientIDNumber,
                  dynamic.decoratorIsNotNullable(dynamic.contains),
                ),
                dynamic.makeFilter(
                  'consultRequestTypeID',
                  consultRequestTypeID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'userEmployeeProfileID',
                  userEmployeeProfileIDs,
                  dynamic.equalsSome,
                ),
                isRoleConsultant &&
                  dynamic.makeFilter(
                    'userEmployeeProfile.appIdentityUserID',
                    isRoleConsultant,
                    dynamic.decoratorIsNotNullable(dynamic.equals),
                  ),
                dynamic.makeFilter(
                  'patientAgeYears',
                  fromAge,
                  dynamic.decoratorIsNotNullable(dynamic.moreOrEquals),
                ),
                dynamic.makeFilter(
                  'patientAgeYears',
                  toAge,
                  dynamic.decoratorIsNotNullable(dynamic.lessOrEquals),
                ),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'entryDate',
              '(userEmployeeProfile.firstName + " " + userEmployeeProfile.lastName).trim() as employee',
              'patientName',
              'patientPhone',
              'patientEmail',
              'consultationDate',
              'patientIDNumber',
              'patientAgeYears',
              'patientAgeMonths',
              'answer',
              'gender.title as gender',
              'stepIndex',
              'lockSession',
              'consultRequestType.title as consultRequestTypeTitle',
              'patientPdf',
              'userEmployeeProfileID',
              'referralReason',
              'insuranceStatus.title as insuranceStatus',
              'knownAllergies',
              'medicalStaffName',
              'answeredByEmail',
              'answeredByPhone',
              'answeredByOther',
              'egfr',
              'consultSessionReferenceSources.Select(c => c.referenceSource.title) as referenceSourceTitles',
              'referralType',
              'referralTypeOther',
              'userEmployeeProfile.idNumber as userEmployeeProfileIDNumber',
              'userEmployeeProfile.fullName as userEmployeeProfileFullName',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceConsultSession.getAllDynamic<
            IGridConsultSessions,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getConsultSession: build.query<IConsultSession, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'rowIndex',
              'createdDate',
              'updatedDate',
              'isActive',
              'userEmployeeProfileID',
              'consultRequestTypeID',
              'insuranceStatusID',
              'genderID',
              'referralTypeID',
              'medicalStaffOptionID',
              'entryDate',
              'consultationDate',
              'patientIDNumber',
              'patientName',
              'patientEmail',
              'patientPhone',
              'patientAgeYears',
              'patientAgeMonths',
              'patientWeight',
              'patientCreatinine',
              'egfr',
              'medicalStaffName',
              'medicalStaffEmail',
              'medicalStaffPhone',
              'referralReason',
              'knownAllergies',
              'answer',
              'answeredByPhone',
              'answeredByEmail',
              'answeredByOther',
              'genderOther',
              'insuranceStatusOther',
              'referralTypeOther',
              'medicalStaffOptionOther',
              'referenceSourceOther',
              'medicalStaffWorkPlaceOther',
              'patientPdf',
              'medicalStaffPdf',
              'consultDisclaimerApproval',
              'consentConfirmationByEmail',
              'stepIndex',
              'id',
              'lockSession',
              'consultSessionReferenceSources',
              'consultSessionMedicalStaffWorkPlaces',
              'medicalStaffOption',
              'gender',
              'userEmployeeProfile.idNumber as userEmployeeProfileIDNumber',
              'userEmployeeProfile.fullName as userEmployeeProfileFullName',
              'new { consultRequestType.disclaimerHtml, consultRequestType.disclaimerSubTitle, consultRequestType.disclaimerTitle, consultRequestType.title} as consultRequestType',
              'consultSessionMedications.Select(k => new {k.consultSessionClassifications }) as consultSessionClassifications',
            ),
          };
          const { data } = await ServiceConsultSession.getDynamic<IConsultSession>(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getSourceConsultSessionMedicalStaffOptionOther: build.query<
      { id: string; medicalStaffOptionOther: string }[],
      void
    >({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'medicalStaffOptionOther'),
          orderBy: dynamic.orderBy('medicalStaffOptionOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getSourceConsultSessionMedicalStaffWorkPlaceOptionOther: build.query<
      { id: string; medicalStaffWorkPlaceOther: string }[],
      void
    >({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'medicalStaffWorkPlaceOther'),
          orderBy: dynamic.orderBy('medicalStaffWorkPlaceOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getSourceConsultSessionAnsweredOptionOther: build.query<
      { id: string; answeredByOther: string }[],
      void
    >({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'answeredByOther'),
          orderBy: dynamic.orderBy('answeredByOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getSourceConsultSessionInsuranceStatusOptionOther: build.query<
      { id: string; insuranceStatusOther: string }[],
      void
    >({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'insuranceStatusOther'),
          orderBy: dynamic.orderBy('insuranceStatusOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getSourceConsultSessionReferralTypeOptionOther: build.query<
      { id: string; referralTypeOther: string }[],
      void
    >({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'referralTypeOther'),
          orderBy: dynamic.orderBy('referralTypeOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getSourceConsultSessionReferenceSourceOptionOther: build.query<
      { id: string; referenceSourceOther: string }[],
      void
    >({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'referenceSourceOther'),
          orderBy: dynamic.orderBy('referenceSourceOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getDashboardKnownAllergiesKPIs: build.query<{ knownAllergies: Unset }[], void>({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('knownAllergies.trim() as knownAllergies'),
          orderBy: 'knownAllergies.trim()',
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getConsultSessionForAnswerOptions: build.query<{ referenceSourceOther: string }[], void>({
      query: () => ({
        url: API_SESSIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('referenceSourceOther.trim() as referenceSourceOther'),
          filter: dynamic
            .mergeFilters('string.isNullOrEmpty(referenceSourceOther)==false')
            .join('&&'),
          orderBy: dynamic.orderBy('referenceSourceOther', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    postConsultSession: build.mutation<ApiModel, ApiModel>({
      queryFn: async (data) => {
        try {
          return await ServiceConsultSession.post(data);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchConsultSession: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceConsultSession.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteConsultSession: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceConsultSession.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});

import { useTranslate } from 'hooks/use-translate';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';

export type FieldRenderProps = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
};

export type Options = {
  translateLabel: boolean;
};
export const useFieldProps = <Shared>(shared?: Shared) => {
  const { t } = useTranslate();

  return useCallback(
    (renderProps: FieldRenderProps, options?: Options) => {
      const { translateLabel = true } = options || {};
      const { field, fieldState } = renderProps;
      const error = fieldState.error;
      return {
        ...field,
        ...shared,
        label: translateLabel ? t(fieldToLabelKey(field.name)) : field.name,
        error: Boolean(error),
        helperText: error && error.message ? t(error.message) : ' ',
      };
    },
    [t, shared],
  );
};

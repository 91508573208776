import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';

const API = 'CustomerTypes';

export const API_CUSTOMER_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ICustomerType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ICustomerType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ICustomerType extends DynamicComponents.Schemas.CustomerType {
  id: string;
}

export const schemaCustomerType = yup.object({
  title: yup.string().required('rule-required').default(''),
  color: yup.string().required('rule-required').nullable().default(''),
  icon: yup.mixed<string>().required('rule-required').nullable().default(''),
  isActive: yup.boolean().default(true),
});
export interface IGridCustomerType
  extends Pick<ICustomerType, 'id' | 'title' | 'rowIndex' | 'isActive' | 'icon' | 'color'> {}

export interface IGetGridCustomerTypesInput {
  search: string;
  active: boolean | null;
  take: number;
  skip: number;
  order: DynamicOrder;
}

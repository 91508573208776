import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseTransformer,
} from 'axios';
import { format } from 'date-fns';
import { ServiceMediaUploads } from 'services/media-uploads';
import { isFileLike, ValueFileUploaderFile } from 'utils/file-uploader';
import { convertToDate, isFullISODateTimeString } from './dates';
import * as dynamic from './dynamic';
import { deepTransform } from './other';
import { ConditionPartial, Kill, PatchPartial } from './types';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,7}$/gi.test(String(value));
};

const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${value.split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

const axiosDateTransformRequest: AxiosRequestTransformer = (data) => {
  if (!data) return data;

  const result = deepTransform(
    data,
    (value) => {
      if (value instanceof Date) {
        return true;
      }
      return typeof value === 'string' && isFullISODateTimeString(value);
    },
    (value) => {
      return format(convertToDate(value), 'yyyy-MM-dd');
    },
  );

  return result;
};
const defaultAxiosTransformRequest = axios.defaults
  .transformRequest as Array<AxiosRequestTransformer>;

export const apiAppDynamic = axios.create({
  baseURL: `/api/proxy`,
  transformResponse: [axiosDateTransformer],
  transformRequest: [axiosDateTransformRequest, ...defaultAxiosTransformRequest],
});

export const apiAppBackend = axios.create({
  baseURL: `/api`,
  transformResponse: [axiosDateTransformer],
  transformRequest: [axiosDateTransformRequest, ...defaultAxiosTransformRequest],
});

export interface DynamicParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

export type DynamicResult<T extends any, P extends DynamicParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[] };

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> =>
  async ({ method, ...rest }) => {
    try {
      const result = await apiAppDynamic({
        ...rest,
        method: method || 'get',
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Customers',
    'Labels',
    'Languages',
    'Projects',
    'ProjectTypes',
    'ProjectStatuses',
    'ProjectAlerts',
    'ProjectPriceLists',
    'ProjectWikiItems',
    'ProjectTimeTracking',
    'UserEmployeeProfilePermissions',
    'UserCrmProfiles',
    'TimeTracking',
    'Regions',
    'NamePrefixesEng',
    'MemberTypes',
    'MedicalStaffWorkPlaces',
    'WorkPlaces',
    'WorkPlaceTypes',
    'Cities',
    'PaymentTypes',
    'Nationalities',
    'NamePrefixes',
    'ExternalChannels',
    'UserPharmacistProfiles',
    'UserPharmacistProfileInvoiceDetails',
    'UserPharmacistProfileInvoices',
    'DashboardReports',
    'Suppliers',
    'Invoices',
    'InvoiceDetails',
    'PaymentTypeSubjects',
    'PaymentAccounts',
    'IncomeAccounts',
    'Genders',
    'InvoiceTypes',
    'UserPharmacistProfileContacts',
    'SupplierContacts',
    'LogisticClassifications',
    'RightsExtractionClassifications',
    'InsuranceStatuses',
    'ReferralTypes',
    'MedicalStaffOptions',
    'ResponsivenessToTreatments',
    'ReferenceSources',
    'ConsultRequestTypes',
    'ClinicalSubjectClassifications',
    'ClinicalItemClassifications',
    'ConsultSessions',
    'ConsultSessionMedicalStaffWorkPlaces',
    'ConsultSessionReferenceSources',
    'ConsultSessionMedications',
    'ConsultSessionClassifications',
    'ConsultSessionClassificationRightsExtractions',
    'ConsultSessionClassificationLogistics',
    'ConsultSessionClassificationClinicalItems',
    'CustomerTypes',
    'PharmaAdvisors',
    'ArticleSubjects',
    'Articles',
    'CustomerContacts',
    'CustomerInvoices',
    'CustomerInvoiceDetails',
    'CommonPages',
    'TeamSubjects',
    'TeamMembers',
    'SiteParameters',
    'SiteParameterFooterLinks',
    'RegDrugs',
    'RegDrugDocuments',
    'EventSessionCategories',
    'SmsMessages',
    'EmailMessages',
    'EventSessions',
    'EventSessionMessages',
    'EventSessionPrices',
    'EventSessionProgramItems',
    'EventSessionSponsors',
    'UserPharmacistProfileEventSessions',
    'UserPharmacistProfileMemberships',
    'EventSessionLinks',
    'EventSessionFiles',
    'UploadedImageFolders',
    'UploadedImages',
  ],
  endpoints: () => ({}),
});

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const isRejectedMutation = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isFulfilledMutation = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};

export const transformResponseDynamic = <T>(data: { value: T[] }) => {
  return data.value;
};
export const transformResponseDynamicItemMaybe = <T>(data: { value: T[] }) => {
  return data.value[0];
};
export const transformResponseDynamicItem = <T>(data: { value: T[] }) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item;
};

export const transformChartResponse = ({
  data,
  valueField,
  nameField,
  enumName,
}: {
  data: any[];
  valueField: string;
  nameField: string;
  enumName?: any;
}) => {
  return data.map((item) => ({
    value: item[valueField],
    name: enumName ? enumName[item[nameField]] : item[nameField],
  }));
};

export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

const prepareFieldWithID = (value: any, key: any) => {
  return value === '' && String(key).endsWith('ID') ? null : value;
};

export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    const value = acc[key];
    acc[key] = prepareFieldWithID(value, key);
    return acc;
  }, data);
};

interface DynamicModel {
  id: string;
}

interface DynamicServiceOptions<M> {
  engine?: AxiosInstance;
  getAll: string;
  post: string;
  patch: (data: PatchPartial<M, keyof M>) => string;
  delete: (data: PatchPartial<M, keyof M>) => string;
}

export class DynamicService<M = DynamicModel> {
  public engine: AxiosInstance = apiAppDynamic;
  public urlGetAll: string;
  public urlPost: string;
  public urlPatch: (data: PatchPartial<M, keyof M>) => string;
  public urlDelete: (data: PatchPartial<M, keyof M>) => string;
  public mainField: string;

  constructor(
    options: DynamicServiceOptions<M> & ConditionPartial<M, DynamicModel, { mainField: keyof M }>,
  ) {
    const { getAll, patch, post, engine = this.engine, mainField } = options;

    this.mainField = String(mainField || 'id');

    this.engine = engine;

    this.urlGetAll = getAll;
    this.urlPatch = patch;
    this.urlPost = post;
    this.urlDelete = options.delete;

    this.getAllDynamic = this.getAllDynamic.bind(this);
    this.getDynamic = this.getDynamic.bind(this);
    this.patch = this.patch.bind(this);
    this.post = this.post.bind(this);
    this.delete = this.delete.bind(this);
  }

  async getAllDynamic<Model = M, Params extends DynamicParams = DynamicParams>(params?: Params) {
    return this.engine.get<DynamicResult<Model, Params>>(this.urlGetAll, { params });
  }

  async getDynamic<Model = M, Params extends DynamicParams = DynamicParams>(
    id: string,
    params?: Params,
  ) {
    const result = await this.getAllDynamic<Model>({
      ...params,
      filter: dynamic
        .mergeFilters(dynamic.makeFilter(this.mainField as any, id, dynamic.equals), params?.filter)
        .join('&&'),
      take: 1,
    });
    const data = result.data.value[0];
    if (!data) {
      throw new Error('record-not-found');
    }
    return { ...result, data };
  }

  async patch(data: Partial<M>): Promise<string> {
    return this.engine.patch(this.urlPatch(data as any), { ...data, [this.mainField]: undefined });
  }

  async post(postData: Partial<M>) {
    return this.engine.post<M>(this.urlPost, postData);
  }

  async delete(data: Partial<M>) {
    return this.engine.delete(this.urlDelete(data as any));
  }
}
export const decoratorWithFiles = <
  T extends {
    getAllDynamic: (params?: Partial<DynamicParams>) => Promise<AxiosResponse<DynamicResult<M>>>;
  },
  M extends Record<string, any>,
>(
  mainField: keyof M,
  ...fileFields: (keyof M)[]
) => {
  return (target: T, propertyName: string, propertyDescriptor: PropertyDescriptor) => {
    const origin = propertyDescriptor.value;

    propertyDescriptor.value = async function (formData: M) {
      const service = this as T;
      let oldData = {} as M;

      const shouldLoadData =
        formData[mainField] &&
        Object.keys(formData).some((formDataKey) =>
          fileFields.some((fileField) => fileField === formDataKey),
        );

      if (shouldLoadData) {
        const {
          data: { value },
        } = await service.getAllDynamic({
          filter: dynamic.makeFilter(String(mainField), formData[mainField], dynamic.equals),
          select: [...fileFields].join(','),
          take: 1,
        });

        oldData = value[0] || {};
      }

      let entriesToUpload: [keyof M, ValueFileUploaderFile][] = [];
      let entriesToDelete: [keyof M, string][] = [];

      fileFields.forEach((key) => {
        if (isFileLike(formData[key])) {
          entriesToUpload.push([key, formData[key]]);
        }
        if (oldData[key] && typeof oldData[key] === 'string' && oldData[key] !== formData[key]) {
          entriesToDelete.push([key, oldData[key]]);
        }
      });

      const entriesDeleted = await Promise.all(
        entriesToDelete.map(async ([key, value]) => {
          await ServiceMediaUploads.remove({ filePath: value });
          return [key, ''];
        }),
      );
      const entriesUploaded = await Promise.all(
        entriesToUpload.map(async ([key, value]) => {
          const {
            data: { filePath },
          } = await ServiceMediaUploads.uploadFile(value);
          return [key, filePath];
        }),
      );
      const updatedData = [...entriesDeleted, ...entriesUploaded].reduce(
        (acc, [key, value]) => {
          // @ts-ignore
          acc[key] = value;
          return acc;
        },
        { ...formData },
      );

      return origin.call(this, updatedData);
    };
  };
};

export const decoratorRank = <
  M extends Record<string, any>,
  T extends {
    getAllDynamic: (
      params: Pick<DynamicParams, 'orderBy'>,
    ) => Promise<AxiosResponse<DynamicResult<M>>>;
  },
>(
  rankField: keyof M,
) => {
  return function (target: T, memberName: string, propertyDescriptor: PropertyDescriptor) {
    const origin = propertyDescriptor.value;

    propertyDescriptor.value = async function (formData: any) {
      const {
        data: { value },
        // @ts-ignore
      } = await this.getAllDynamic({
        take: 1,
        select: rankField,
        orderBy: `${String(rankField)} desc `,
      });
      const rankValue = value[0] || { [rankField]: 0 };
      return origin.call(this, { ...formData, [rankField]: rankValue[rankField] + 1 });
    };
  };
};

interface MoveRowsOptions<T extends Record<string, any>> {
  mainField: keyof T;
  moveField: keyof T;
  requestPatch: (data: Partial<T>) => void;
  newRows: Partial<T>[];
  oldRows: Partial<T>[];
}
export const behaviourMoveRows = async <T extends Record<string, any>>(
  options: MoveRowsOptions<T>,
) => {
  const { newRows, oldRows, requestPatch } = options;

  const initOrder = Number(oldRows[0][options.moveField]);

  const items = newRows.map((row, i) => {
    return {
      __id: row[options.mainField],
      __newValue: initOrder + i,
    };
  });

  const itemsToPatch = items.filter((row, i) => {
    return row.__newValue !== newRows[i][options.moveField];
  });

  return Promise.all(
    itemsToPatch.map((item) => {
      return requestPatch({
        [options.mainField]: item.__id,
        [options.moveField]: item.__newValue,
      } as Partial<T>);
    }),
  );
};

export const normalizeForm = <T extends Record<string, any>>(data: T): Kill<T> => {
  return Object.keys(data).reduce(
    (acc, key) => {
      if (acc[key] === null) {
        // @ts-ignore
        acc[key] = '';
      }
      return acc;
    },
    { ...data },
  );
};

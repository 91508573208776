import * as yup from 'yup';
import { FixServerObject } from 'utils/types';

const API = 'Cities';

export const API_CITIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ICity, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ICity, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ICity extends FixServerObject<DynamicComponents.Schemas.City, 'id'> {}

export const schemaCities = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});
export interface IGridCity extends Pick<ICity, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}

import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ConsultSessionMedications';

export const API_CONSULT_SESSION_MEDICATIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ConsultSessionMedication, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ConsultSessionMedication, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IConsultSessionMedication
  extends FixServerObject<DynamicComponents.Schemas.ConsultSessionMedication, 'id'> {}

export const schemaConsultSessionMedications = yup
  .object({
    title: yup.mixed<string>().test('is-undefined', 'Rule required for undefined value', function (value) {
      return (
        value !== undefined ||
        this.createError({ path: 'title', message: 'Rule required for undefined value' })
      );
    }),
    responsivenessToTreatmentID: yup.string().required('rule-required'),
    remarks: yup.string(),
    diagnostics: yup.string(),
  })
  .concat(
    yup.object({
      title: yup
        .string()
        .required('rule-required')
        .test(
          'is-valid',
          'Title should only contain English letters and numbers',
          function (value) {
            return (
              /^[a-zA-Z0-9\s]+$/.test(value || '') ||
              this.createError({
                path: 'title',
                message: 'Title should only contain English letters and numbers',
              })
            );
          },
        ),
    }),
  );

export class ConsultSessionMedication
  implements yup.InferType<typeof schemaConsultSessionMedications>
{
  id!: string;
  responsivenessToTreatment = '';
  title = '';
  diagnostics = '';
  remarks = '';
  consultSessionID = '';
  responsivenessToTreatmentID = '';
}

export interface IGridConsultSessionMedication
  extends Pick<
    IConsultSessionMedication,
    'id' | 'diagnostics' | 'remarks' | 'consultSessionID' | 'responsivenessToTreatmentID' | 'title'
  > {
  responsivenessToTreatment: string;
}

import { ActionMatchingPattern } from '@redux-saga/types';
import { addSeconds } from 'date-fns';
import { apiUserPharmacistProfileInvoices } from 'services/user-pharmacist-profile-invoices';
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  SagaGenerator,
  select,
  takeEvery,
} from 'typed-redux-saga';
import { convertToDate } from 'utils/dates';
import { apiRtk } from 'utils/service';
import { BlockedInvoiceReason } from './helpers';
import {
  selectUserPharmacistProfilesBlockedInvoices,
  selectUserPharmacistProfilesBlockInvoices,
} from './selectors';
import {
  actionsUserPharmacistProfiles,
  actionUserPharmacistProfilesBlock,
  actionUserPharmacistProfilesUnBlock,
} from './slice';

const getDelayInSeconds = (reason: BlockedInvoiceReason) => {
  switch (reason) {
    case BlockedInvoiceReason.IMPORT_EXCEL:
      return 30;
    default:
      return 60;
  }
};

const ACTION_DUPLICATE =
  apiUserPharmacistProfileInvoices.endpoints.duplicateUserPharmacistProfileInvoice.matchFulfilled;
const ACTION_CONVERT =
  apiUserPharmacistProfileInvoices.endpoints.convertUserPharmacistProfileInvoices.matchFulfilled;
const ACTION_RECEIPT =
  apiUserPharmacistProfileInvoices.endpoints.createReceiptUserPharmacistProfileInvoices
    .matchFulfilled;

function* sagaBlockTimer(): SagaGenerator<any> {
  const initInvoices = yield* select(selectUserPharmacistProfilesBlockInvoices);

  for (let item of initInvoices) {
    const nowTime = new Date().getTime();
    const endTime = convertToDate(item.endDate).getTime();
    if (nowTime > endTime) {
      yield* put(actionUserPharmacistProfilesUnBlock(item));
    }
  }

  const newInvoices = yield* select(selectUserPharmacistProfilesBlockInvoices);

  if (newInvoices.length > 0) {
    yield* delay(1000);
    yield* call(sagaBlockTimer);
  }

  yield* cancel();
}

function* sagaBlocking() {
  let task = yield* fork(sagaBlockTimer);

  yield* takeEvery(
    [actionUserPharmacistProfilesBlock, actionUserPharmacistProfilesUnBlock],
    function* watcher() {
      if (task && task.isRunning()) return;
      task = yield* fork(sagaBlockTimer);
    },
  );
}

function* workerBlockedInvoices() {
  const blocked = yield* select(selectUserPharmacistProfilesBlockedInvoices);

  for (let invoiceID in blocked) {
    const blocker = blocked[invoiceID];

    const estimatedEndTime = addSeconds(blocker.startTimestamp, getDelayInSeconds(blocker.reason));

    // remove the blocker
    if (estimatedEndTime < new Date()) {
      yield* put(actionsUserPharmacistProfiles.removeInvoiceBlocker(invoiceID));
      yield* put(
        apiRtk.util.invalidateTags([
          { type: 'UserPharmacistProfileInvoices' },
          { type: 'UserPharmacistProfileInvoiceDetails' },
        ]),
      );
    }
  }
}
function* sagaBlockedInvoices() {
  while (true) {
    // each 20 seconds
    yield* delay(20_000);
    yield* call(workerBlockedInvoices);
  }
}
function* watchDuplicate(action: ActionMatchingPattern<typeof ACTION_DUPLICATE>) {
  const {
    meta: {
      arg: { originalArgs },
    },
    payload,
  } = action;

  const invoices = [
    // Invoice from where we are copying
    originalArgs,
    // New invoice to where we are copying
    payload.id,
  ];

  yield* all(
    invoices.map((invoiceID) => {
      return put(
        actionsUserPharmacistProfiles.addInvoiceBlocker({
          invoiceID,
          reason: BlockedInvoiceReason.DUPLICATE,
        }),
      );
    }),
  );
}
function* watchConvert(action: ActionMatchingPattern<typeof ACTION_CONVERT>) {
  const {
    meta: {
      arg: { originalArgs },
    },
    payload,
  } = action;

  const invoices = [
    // Invoice from where we are converting
    originalArgs.invoiceID,
    // New invoice to where we are converting,
    payload.id,
  ];

  yield* all(
    invoices.map((invoiceID) => {
      return put(
        actionsUserPharmacistProfiles.addInvoiceBlocker({
          invoiceID,
          reason: BlockedInvoiceReason.CONVERT,
        }),
      );
    }),
  );
}
function* watchReceipt(action: ActionMatchingPattern<typeof ACTION_RECEIPT>) {
  const {
    meta: {
      arg: { originalArgs },
    },
    payload,
  } = action;

  const invoiceIDs = originalArgs.invoiceIDs;

  const invoices = [...invoiceIDs, payload.id];

  yield* all(
    invoices.map((invoiceID) => {
      return put(
        actionsUserPharmacistProfiles.addInvoiceBlocker({
          invoiceID,
          reason: BlockedInvoiceReason.RECEIPT,
        }),
      );
    }),
  );
}

export const sagasUserPharmacistProfiles = [
  sagaBlocking(),
  sagaBlockedInvoices(),
  takeEvery(ACTION_DUPLICATE, watchDuplicate),
  takeEvery(ACTION_CONVERT, watchConvert),
  takeEvery(ACTION_RECEIPT, watchReceipt),
];

import { format } from 'date-fns';
import * as dynamic from 'utils/dynamic';
import * as yup from 'yup';

const API = 'CustomerInvoices';

export interface CustomerInvoice extends DynamicComponents.Schemas.CustomerInvoice {
  id: string;
  customerID: string;
  invoiceNumber: string;
}

export const API_CUSTOMER_INVOICES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<CustomerInvoice, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<CustomerInvoice, 'id'>) => `${API}/Delete/${data.id}`,
  SEND_EMAIL: `${API}/SendEmail`,
  VALIDATE_EXCEL: `${API}/ValidateExcel`,
  UPLOAD_BULK_INVOICE_DETAILS: `${API}/UploadBulkInvoiceDetails`,

  DUPLICATE: (invoiceID: string) => `${API}/Duplicate/${invoiceID}`,
  CONVERT_TO: `${API}/Convert`,
  RECEIPT: `${API}/Receipt`,
};

export const schemaCustomerInvoice = yup.object({
  invoiceNumber: yup
    .string()
    .required('rule-required')
    .default(() => `P${format(new Date(), 'yyyyMMdd.HHmm')}`),
  customerID: yup.string().required('rule-required').default(''),
  invoiceDate: yup
    .string()
    .required('rule-required')
    .default(() => new Date().toISOString()),
  invoiceTypeID: yup.string().required('rule-required').default(''),
  poNumber: yup.string().nullable().notRequired().default(null),
  remarks: yup.string().nullable().notRequired().max(250, 'rule-max-length').default(''),
  cardRemarks: yup.string().nullable().notRequired().default(''),
  internalRemarks: yup.string().nullable().notRequired().default(null),
  invoiceURL: yup.string().nullable().notRequired().default(null),
  ignore: yup.boolean().notRequired().nonNullable().default(false),
  isSent: yup.boolean().notRequired().nonNullable().default(false),
});

export const schemaCustomerInvoiceImportFromExcel = yup.object({
  customerInvoiceID: yup.string().required('rule-required').default(''),
  quantity: yup.number().integer('rule-integer').required('rule-required').default(1),
  paymentTypeID: yup
    .string()
    .required('rule-required')
    .default('7a541852-98dd-4179-a86f-ea307b87da11'),
  incomeAccountID: yup
    .string()
    .required('rule-required')
    .default('b894fa2a-d43b-4bea-9e61-8aecba41ac4c'),
  amount: yup.number().required('rule-required').min(0.1, 'rule-min').default(0.1),
  duration: yup.number().required('rule-required').default(0),
  fromDate: yup.string().notRequired().nullable().default(''),
  toDate: yup.string().notRequired().nullable().default(null),
  remarks: yup.string().notRequired().nullable().default(null),
  file: yup.mixed<string>().nullable().notRequired().default(null),
});

export interface CustomerInvoiceExtended
  extends Pick<
    CustomerInvoice,
    | 'id'
    | 'invoiceNumber'
    | 'customerID'
    | 'invoiceTypeID'
    | 'poNumber'
    | 'remarks'
    | 'isActive'
    | 'invoiceDate'
    | 'paymentDate'
    | 'isPaid'
    | 'isSent'
    | 'invoiceURL'
    | 'ignore'
    | 'internalRemarks'
    | 'cardRemarks'
  > {
  detailsCount: number;
  contactName: string;
  invoiceTypeTitle: string;
}

export interface GridCustomerInvoice
  extends Pick<
    CustomerInvoice,
    | 'id'
    | 'invoiceDate'
    | 'invoiceNumber'
    | 'isActive'
    | 'paymentDate'
    | 'invoiceURL'
    | 'isPaid'
    | 'isSent'
    | 'customerID'
    | 'invoiceTypeID'
    | 'internalRemarks'
    | 'cardRemarks'
    | 'ignore'
  > {
  totalAmount: number;
  invoiceTypeTitle: string;
  paymentRequired: boolean;
  step: number;
  contactName: string;
}
export interface GridCustomerInvoiceInput {
  search: string;
  take?: number;
  skip?: number;
  orderBy: dynamic.DynamicOrder;
  customerID: string;
  active: boolean | null;
}

export interface CreateReceiptInput {
  invoiceIDs: Array<string>;
  customerID: string;
  invoiceTypeID: string;
  cardRemarks: string;
}

export interface ConvertCustomerInvoiceInput {
  invoiceID: string;
  toInvoiceTypeID: string;
  internalRemarks: string;
  cardRemarks: string;
}

export type SendCustomerInvoiceInput = {
  email: string;
  invoiceID: string;
};

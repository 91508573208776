import { Box, Paper, Theme, useMediaQuery } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Panel } from './components';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  return (
    <Box className={style.root}>
      {!isMobile && <Panel />}
      <Paper className={style.content}>
        <Header />
        <Box>
          <Outlet />
        </Box>
      </Paper>
    </Box>
  );
});

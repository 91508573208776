import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { EVENT_SESSION_STATUS } from 'components/event-session-status';
import { apiEventSessions } from 'services/event-sessions';
import { DynamicOrder } from 'utils/dynamic';
import { actionAccountLogout } from '../auth';
import { actionEventSessionsGetAllDynamic } from './actions';
import { IGridEventSession, NAMESPACE } from './helpers';

interface Filters {
  search: string;
  startRange: string[];
  eventSessionCategoryIDs: string[];
  active: boolean | null;
  status: EVENT_SESSION_STATUS;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  isMounted: boolean;
  data: IGridEventSession[];
  filters: Filters;
  pagination: Pagination;
  orderBy: DynamicOrder;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    isMounted: false,
    data: [],
    filters: {
      search: '',
      startRange: [],
      eventSessionCategoryIDs: [],
      active: true,
      status: EVENT_SESSION_STATUS.ONGOING,
    },
    pagination: {
      take: 100,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'startDate',
      order: 'asc',
    },
  };
};

const slice = createSlice({
  name: NAMESPACE,
  initialState: initState(),
  reducers: {
    mounted(state, action: PayloadAction<boolean>) {
      state.isMounted = action.payload;
    },
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    orderBySet(state, action: PayloadAction<Partial<DynamicOrder>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionEventSessionsGetAllDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionEventSessionsGetAllDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value;
        state.pagination.count = count;
      })
      .addCase(actionEventSessionsGetAllDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });

    builder.addMatcher(
      isAnyOf(
        apiEventSessions.endpoints.deleteEventSession.matchFulfilled,
        apiEventSessions.endpoints.updateEventSession.matchFulfilled,
      ),
      (state) => {
        state.isInit = false;
      },
    );
  },
});

export const actionsEventSessions = slice.actions;
export const reducerEventSessions = slice.reducer;

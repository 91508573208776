import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.eventSessions;

export const selectEventSessionsStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit, isMounted }) => {
    return { isLoading, error, isInit, isMounted };
  },
);
export const selectEventSessionsData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectEventSessionsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectEventSessionsPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectEventSessionsOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});

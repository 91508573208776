import * as dynamic from 'utils/dynamic';
import { apiRtk, decoratorWithFiles, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_CUSTOMER_TYPES,
  ICustomerType,
  IGetGridCustomerTypesInput,
  IGridCustomerType,
} from './models';

const REVALIDATE_KEY = 'CustomerTypes' as const;

type ApiModel = ICustomerType;

class Service extends DynamicService<ApiModel> {
  @decoratorWithFiles('id', 'icon')
  async post(data: Partial<ApiModel>) {
    return super.post(data);
  }

  @decoratorWithFiles('id', 'icon')
  async patch(data: Partial<ApiModel>) {
    return super.patch(data);
  }
}

export const ServiceCustomerTypes = new Service({
  mainField: 'id',
  getAll: API_CUSTOMER_TYPES.GET_ALL_DYNAMIC,
  post: API_CUSTOMER_TYPES.POST,
  patch: API_CUSTOMER_TYPES.PATCH,
  delete: API_CUSTOMER_TYPES.DELETE,
});

type SourceModel = { id: string; title: string; isActive: boolean; color: string; icon: string };

export const apiCustomerTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceCustomerTypes: build.query<Array<SourceModel>, void>({
      queryFn: async () => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive', 'color', 'icon'),
            orderBy: dynamic.orderBy('title', 'asc'),
          };
          const { data } = await ServiceCustomerTypes.getAllDynamic<SourceModel, typeof params>(
            params,
          );
          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridCustomerTypes: build.query<
      DynamicResult<IGridCustomerType, { count: true }>,
      IGetGridCustomerTypesInput
    >({
      queryFn: async ({ search, order, take, skip, active }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('title', search, dynamic.contains),
                dynamic.makeFilter(
                  'isActive',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'isActive', 'icon', 'color'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceCustomerTypes.getAllDynamic<
            IGridCustomerType,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getCustomerType: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive', 'icon', 'color'),
          };
          const { data } = await ServiceCustomerTypes.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postCustomerType: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceCustomerTypes.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchCustomerType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceCustomerTypes.patch({
            ...data,
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(id) },
      ],
    }),
    deleteCustomerType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceCustomerTypes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(id) },
      ],
    }),
  }),
});

import { convertToDate, isDateLike } from 'utils/dates';
import * as yup from 'yup';
import { InferType } from 'yup';

const API = 'EventSessions';

export const API_EVENT_SESSIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IEventSession, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IEventSession, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IEventSession extends DynamicComponents.Schemas.EventSession {
  id: string;
}
export const schemaEventSession = yup.object({
  id: yup.string().required('rule-required').default(''),
  title: yup.string().required('rule-required').default(''),
  subTitle: yup.string().notRequired().nullable().default(undefined),
  eventSessionCategoryID: yup.string().required('rule-required').default(''),

  htmlContent: yup.string().notRequired().nullable().default(undefined),

  presenter: yup.string().notRequired().nullable().default(undefined),

  picture: yup.mixed<string>().nullable().notRequired().default(null),
  banner: yup.mixed<string>().nullable().notRequired().default(null),

  startDate: yup
    .string()
    .required('rule-required')
    .test('startDate', 'rule-date', isDateLike)
    .test('startDate', 'rule-date-start', (value, context) => {
      const startDate = value;

      if (!startDate) return true;

      const endDate = context.parent.endDate;
      if (!endDate) return true;

      const startTime = convertToDate(startDate).getTime();
      const endTime = convertToDate(endDate).getTime();

      return startTime <= endTime;
    })
    .default(''),
  endDate: yup
    .string()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .test('endDate', 'rule-date-end', (value, context) => {
      const endDate = value;

      if (!endDate) return true;

      const startDate = context.parent.startDate;
      if (!startDate) return true;

      const startTime = convertToDate(startDate).getTime();
      const endTime = convertToDate(endDate).getTime();

      return startTime <= endTime;
    })
    .default(''),

  // custom fields
  timeSlot: yup.string().notRequired().nullable().default(''),
});

export const schemaEventSessionPost = schemaEventSession.pick([
  'title',
  'eventSessionCategoryID',
  'startDate',
  'endDate',
  'timeSlot',
]);

export type EventSessionPostInput = InferType<typeof schemaEventSessionPost>;
export type EventSessionUpdateInput = InferType<typeof schemaEventSession>;
export type IAppEventSession = InferType<typeof schemaEventSession>;

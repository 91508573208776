import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'MemberTypes';

export const API_MEMBER_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IMemberType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IMemberType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IMemberType extends FixServerObject<DynamicComponents.Schemas.MemberType, 'id'> {}

export const schemaMemberTypes = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});
export interface IGridMemberType
  extends Pick<IMemberType, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}

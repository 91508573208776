import { Button, ButtonProps, ButtonTypeMap, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import * as React from 'react';
import { useMemo } from 'react';

export const ButtonCreate = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = {},
>({
  title,
  children,
  ...rest
}: ButtonProps<D, P> & { title?: string }) => {
  const { tp } = useTranslate();
  const _title = useMemo(() => {
    if (children === tp('create')) {
      return tp('create-tooltip');
    }
    if (children === tp('save')) {
      return tp('save-tooltip');
    }
    return tp('submit');
  }, [children, tp]);
  return (
    <Tooltip title={title || _title}>
      <Button variant={'contained'} {...rest}>
        {children || tp('create')}
      </Button>
    </Tooltip>
  );
};

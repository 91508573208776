import { format } from 'date-fns';
import * as yup from 'yup';

const API = 'UserPharmacistProfiles';

export const API_USER_PHARMACIST_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IUserPharmacistProfile, 'appIdentityUserID'>) =>
    `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<IUserPharmacistProfile, 'appIdentityUserID'>) =>
    `${API}/Delete/${data.appIdentityUserID}`,
  CREATE_FROM_EXCEL: `${API}/CreateBulkUsersFromExcel`,
};
export const schemaUserPharmacistProfilePost = yup.object({
  firstName: yup.string().notRequired().nullable().default(''),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  idNumber: yup.string().required('rule-required').default(''),
  email: yup
    .string()
    .required('rule-required')
    .email('rule-email')
    .trim()
    .default(() => {
      return `${format(new Date(), 'yyyyMMddHHmm')}@email.com`;
    }),
  phoneMobile: yup.string().typeError('rule-required').required('rule-required').trim().default(''),
  pharmacistCode: yup.string().required('rule-required').default(''),
});

export const schemaUserPharmacistProfilePatch = schemaUserPharmacistProfilePost.shape({
  graduationYear: yup
    .number()
    .integer('rule-integer')
    .notRequired()
    .nullable()
    .min(1902, 'rule-min-length')
    .max(2022, 'rule-max-length')
    .default(null),
  namePrefixID: yup.string().notRequired().nullable().default(null),
  namePrefixEngID: yup.string().notRequired().nullable().default(null),
  firstNameEng: yup.string().notRequired().nullable().default(''),
  lastNameEng: yup.string().notRequired().nullable().default(''),
  licenseNumber: yup.string().notRequired().nullable().default(''),
  pharmacistCode: yup.string().required('rule-required').default(''),
  remarks: yup.string().notRequired().nullable().default(''),
  phoneWork: yup.string().notRequired().nullable().default(''),
  regionID: yup.string().notRequired().nullable().default(''),
  emailAdditional: yup.string().email('rule-email').notRequired().nullable().default(''),
  phoneHome: yup.string().notRequired().nullable().default(''),
  fax: yup.string().notRequired().nullable().default(''),
  address: yup.string().notRequired().nullable().default(''),
  zipCode: yup.string().notRequired().nullable().default(''),
  cityID: yup.string().notRequired().nullable().default(''),
  country: yup.string().notRequired().nullable().default(''),
  nationalityID: yup.string().notRequired().nullable().default(null),
  dateOfBirth: yup.string().notRequired().nullable().default(null),
  workPlaceID: yup.string().notRequired().nullable().default(null),
  workPlaceTypeID: yup.string().notRequired().nullable().default(null),
  memberTypeID: yup.string().notRequired().nullable().default(null),
  position: yup.string().notRequired().nullable().default(''),
  positionOrg: yup.string().notRequired().nullable().default(''),
  genderID: yup.string().notRequired().nullable().default(null),
  stateEmployee: yup.boolean().default(false),
  clinicalPharmacist: yup.boolean().default(false),
  allowToSendNotification: yup.boolean().default(false),
  allowToSendNewsletter: yup.boolean().default(false),
  allowToSendSMS: yup.boolean().default(false),
  isActive: yup.boolean().default(true),
  distributionType: yup
    .number()
    .integer('rule-integer')
    .notRequired()
    .nullable()
    .default(undefined),
});

export interface IUserPharmacistProfile extends DynamicComponents.Schemas.UserPharmacistProfile {
  id: string;
  appIdentityUserID: string;
}

import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'LogisticClassifications';

export const API_LOGISTIC_CLASSIFICATIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ILogisticClassification, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ILogisticClassification, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ILogisticClassification
  extends FixServerObject<
    DynamicComponents.Schemas.LogisticClassification,
    'id'
  > {}

export const schemaLogisticClassification = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
  isOther: yup.boolean().default(false),
});

export interface IGridLogisticClassification
  extends Pick<
    ILogisticClassification,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'isOther' | 'rank'
  > {}

import * as yup from 'yup';

const API = 'UserPharmacistProfileContacts';

export const API_USER_PHARMACIST_PROFILE_CONTACTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IUserPharmacistProfileContact, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IUserPharmacistProfileContact, 'id'>) => `${API}/Delete/${data.id}`,
};
export const schemaUserPharmacistProfileContacts = yup.object({
  userPharmacistProfileID: yup.string().required('rule-required').default(''),
  name: yup.string().required('rule-required').trim().default(''),
  email: yup.string().required('rule-required').email('rule-email').trim().default(''),
  department: yup.string().nullable().notRequired().default(''),
  phoneMobile: yup.string().nullable().notRequired().trim().default(''),
  phoneWork: yup.string().nullable().notRequired().trim().default(''),
  phoneHome: yup.string().nullable().notRequired().trim().default(''),
  remarks: yup.string().nullable().notRequired().trim().default(''),
});

export interface IUserPharmacistProfileContact
  extends DynamicComponents.Schemas.UserPharmacistProfileContact {
  id: string;
  userPharmacistProfileID: string;
}

export interface IGridUserPharmacistProfileContacts
  extends Pick<
    IUserPharmacistProfileContact,
    'id' | 'name' | 'department' | 'email' | 'phoneMobile' | 'phoneHome' | 'phoneWork' | 'remarks'
  > {}

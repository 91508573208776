import { Box } from '@mui/material';
import { HeaderPortal } from 'components/header-portal';
import { HeaderTitle } from 'components/header-title';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const CmsPagesLayout = memo(() => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.CMS_PAGES.path,
        to: APP_ROUTES.CMS_PAGES().config,
        label: tp('home'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_CONTACT_US.path,
        to: APP_ROUTES.CMS_PAGE_CONTACT_US().config,
        label: tp('contact-us'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_THANKS.path,
        to: APP_ROUTES.CMS_PAGE_THANKS().config,
        label: tp('contact-us-thanks'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_SEARCH.path,
        to: APP_ROUTES.CMS_PAGE_SEARCH().config,
        label: tp('search-result'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_COURSES.path,
        to: APP_ROUTES.CMS_PAGE_COURSES().config,
        label: tp('courses'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_FORUMS.path,
        to: APP_ROUTES.CMS_PAGE_FORUMS().config,
        label: tp('forums'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_WEBINARS.path,
        to: APP_ROUTES.CMS_PAGE_WEBINARS().config,
        label: tp('webinars'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_PHARMA_RULES.path,
        to: APP_ROUTES.CMS_PAGE_PHARMA_RULES().config,
        label: tp('pharma-rules'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_DRUGS.path,
        to: APP_ROUTES.CMS_PAGE_DRUGS().config,
        label: tp('drugs'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_PHARMACISTS.path,
        to: APP_ROUTES.CMS_PAGE_PHARMACISTS().config,
        label: tp('pharmacists'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_ARTICLES.path,
        to: APP_ROUTES.CMS_PAGE_ARTICLES().config,
        label: tp('articles'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_ETHICS_CODE.path,
        to: APP_ROUTES.CMS_PAGE_ETHICS_CODE().config,
        label: tp('ethics-code'),
      },
      {
        value: APP_ROUTES.CMS_PAGE_BENEFITS.path,
        to: APP_ROUTES.CMS_PAGE_BENEFITS().config,
        label: tp('benefits'),
      },
    ];
  }, [tp]);

  return (
    <>
      <HeaderPortal>
        <HeaderTitle children={tp('pages')} />
      </HeaderPortal>
      <Box className={style.root}>
        <Box className={style.inner}>
          <TabsPage tabs={tabs} />
        </Box>
      </Box>
    </>
  );
});

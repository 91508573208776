import * as dynamic from 'utils/dynamic';

export enum EVENT_SESSION_STATUS {
  ONGOING = 'event-ongoing',
  ARCHIVE = 'event-archive',
}

export const eventSessionStatusValueCreator: dynamic.ValueCreator = (
  name,
  value: EVENT_SESSION_STATUS,
) => {
  const start = [name, 'startDate'].filter(Boolean).join('.');
  const end = [name, 'endDate'].filter(Boolean).join('.');

  if (value === EVENT_SESSION_STATUS.ARCHIVE) {
    return dynamic
      .mergeFilters(dynamic.makeFilter(`DateTime(${end}).Date`, 'DateTime.Today', dynamic.less))
      .join('&&');
  }

  if (value === EVENT_SESSION_STATUS.ONGOING) {
    return dynamic
      .mergeFilters(
        dynamic.makeFilter(`DateTime(${start}).Date`, 'DateTime.Today', dynamic.lessOrEquals),
        dynamic.makeFilter(`DateTime(${end}).Date`, 'DateTime.Today', dynamic.moreOrEquals),
      )
      .join('&&');
  }

  return undefined;
};

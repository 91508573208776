import { memo, useMemo } from 'react';
import { apiMediaPrivate } from 'services/media-private';

const useFetchQuery = apiMediaPrivate.useGetPrivateFileQuery;

const imageLoading = '/images/image-loader.gif';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number | string;
  height?: number | string;
}

export const AppImage = memo<Props>(({ src, style, width, height, alt, ...props }) => {
  const isPrivate = useMemo(() => {
    return (src || '').includes('/private/');
  }, [src]);

  const { data, isLoading } = useFetchQuery(src || '', {
    skip: !isPrivate,
  });

  const srcImage = useMemo(() => {
    if (isLoading) {
      return imageLoading;
    }
    if (isPrivate) {
      return data;
    }
    return src;
  }, [isPrivate, data, src, isLoading]);

  return (
    <img
      src={srcImage}
      alt={alt || ''}
      style={{
        filter: isLoading ? 'blur(4px)' : 'none',
        ...style,
        width: width ?? '100%',
        height: height ?? 'auto',
      }}
      {...props}
    />
  );
});

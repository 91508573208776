import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'PaymentAccounts';

export const API_PAYMENT_ACCOUNTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IPaymentAccount, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IPaymentAccount, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IPaymentAccount
  extends FixServerObject<DynamicComponents.Schemas.PaymentAccount, 'id'> {}

export const schemaPaymentAccounts = yup.object({
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
  accountCode: yup.string().notRequired().nullable().default(null),
});

export interface IGridPaymentAccounts
  extends Pick<IPaymentAccount, 'id' | 'title' | 'rowIndex' | 'isActive' | 'accountCode'> {}

export interface IDashboardPaymentAccountsArgs {
  search: string;
  orderBy: DynamicOrder;
}
export interface IDashboardPaymentAccounts
  extends Pick<IPaymentAccount, 'id' | 'title' | 'accountCode'> {}

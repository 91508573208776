import React from 'react';

import style from './index.module.scss';

import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { ButtonGoTo } from 'components/buttons';
import { APP_ROUTES } from 'configs';
import { HEADER_PORTAL_ID } from 'configs/const';
import { usePermissions, useTranslate } from 'hooks';
import { Panel } from 'layouts/private/components/panel';
import { useMatch } from 'react-router-dom';
import { HeaderProfile } from '../header-profile';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const Header: React.FC<Props> = ({ className, classes }) => {
  const { tp } = useTranslate();
  const isDashboard = useMatch(APP_ROUTES.DASHBOARD.path);
  const isPharmacists = useMatch(APP_ROUTES.PHARMACISTS_LIST.path);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const permissions = usePermissions();
  return (
    <Box className={clsx(style.root, classes?.root, className)}>
      <Box id={HEADER_PORTAL_ID} className={style.title} />
      <Box className={style.wrap}>
        {isMobile && <Panel isResponsive />}
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          flexGrow={isSm ? 1 : 0}
          mr={-1.6}
        >
          <Stack direction={isSm ? 'column' : 'row'} flexGrow={1} spacing={isSm ? 0.5 : 1}>
            {!isDashboard && (
              <ButtonGoTo to={APP_ROUTES.DASHBOARD().config} size={isSm ? 'small' : 'medium'}>
                {tp('dashboard')}
              </ButtonGoTo>
            )}
            {!isPharmacists && permissions.isAdmin && (
              <ButtonGoTo
                to={APP_ROUTES.PHARMACISTS_LIST().config}
                size={isSm ? 'small' : 'medium'}
              >
                {tp('pharmacists-list')}
              </ButtonGoTo>
            )}
          </Stack>
          <HeaderProfile />
        </Stack>
      </Box>
    </Box>
  );
};

import * as yup from 'yup';

const API = 'Languages';

export const API_LANGUAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  CREATE: `${API}/Create`,
  PATCH: (data: Pick<ILanguage, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ILanguage, 'id'>) => `${API}/Delete/${data.id}`,
};

export enum DIRECTION {
  RTL = 2,
  LTR = 1,
}

export interface ILanguage extends Omit<DynamicComponents.Schemas.Language, 'direction'> {
  direction: DIRECTION;
  id: string
}



export const schemaLanguages = yup.object({
  title: yup.string().required('rule-required').default(''),
  icon: yup.mixed<string>().nullable().required('rule-required').default(''),
  culture: yup.string().required('rule-required').default(''),
  direction: yup.number().required('rule-required').default(DIRECTION.LTR),
  currency: yup.string().notRequired().nonNullable().default(''),
  isActive: yup.boolean().default(true),
});

export interface IGridLanguage
  extends Pick<ILanguage, 'id' | 'title' | 'culture' | 'rank' | 'isActive'> {}

import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_USER_PHARMACIST_PROFILE_CONTACTS,
  IGridUserPharmacistProfileContacts,
  IUserPharmacistProfileContact,
} from './models';

class Service extends DynamicService<IUserPharmacistProfileContact> {}

const REVALIDATE_TAG = 'UserPharmacistProfileContacts' as const;

export * from './models';

export const ServiceUserPharmacistProfileContacts = new Service({
  getAll: API_USER_PHARMACIST_PROFILE_CONTACTS.GET_ALL_DYNAMIC,
  post: API_USER_PHARMACIST_PROFILE_CONTACTS.POST,
  patch: API_USER_PHARMACIST_PROFILE_CONTACTS.PATCH,
  delete: API_USER_PHARMACIST_PROFILE_CONTACTS.DELETE,
});

type ApiModel = IUserPharmacistProfileContact;

export interface IGridUserPharmacistProfileContactsParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: dynamic.DynamicOrder;
  userPharmacistProfileID: string;
}

export const apiUserPharmacistProfileContacts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridUserPharmacistProfileContacts: build.query<
      DynamicResult<IGridUserPharmacistProfileContacts, { count: true }>,
      IGridUserPharmacistProfileContactsParams
    >({
      queryFn: async ({ orderBy, search, take, skip, userPharmacistProfileID }) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'name',
              'department',
              'email',
              'phoneMobile',
              'phoneHome',
              'phoneWork',
              'remarks',
            ),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  [
                    'name',
                    'department',
                    'email',
                    'phoneMobile',
                    'phoneHome',
                    'phoneWork',
                    'remarks',
                  ],
                  search,
                  dynamic.contains,
                ),
                dynamic.makeFilter(
                  'userPharmacistProfileID',
                  userPharmacistProfileID,
                  dynamic.equals,
                ),
              )
              .join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            take,
            skip,
          };
          const { data } = await ServiceUserPharmacistProfileContacts.getAllDynamic<
            IGridUserPharmacistProfileContacts,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfileContact: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'name',
              'email',
              'department',
              'phoneMobile',
              'phoneHome',
              'phoneWork',
              'remarks',
            ),
          };
          const { data } = await ServiceUserPharmacistProfileContacts.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getUserPharmacistProfileContactsEmail: build.query<
      Pick<IGridUserPharmacistProfileContacts, 'id' | 'name' | 'department' | 'email'>[],
      { userPharmacistProfileID: string }
    >({
      query: ({ userPharmacistProfileID }) => ({
        url: API_USER_PHARMACIST_PROFILE_CONTACTS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'name', 'department', 'email'),
          orderBy: 'name desc',
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                'userPharmacistProfileID',
                userPharmacistProfileID,
                dynamic.equals,
              ),
            )
            .join('&&'),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postUserPharmacistProfileContacts: build.mutation<ApiModel, Omit<ApiModel, 'id'>>({
      queryFn: async (input) => {
        try {
          const result = await ServiceUserPharmacistProfileContacts.post(input);
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserPharmacistProfileContacts: build.mutation<
      PatchPartial<ApiModel, 'id'>,
      PatchPartial<ApiModel, 'id'>
    >({
      queryFn: async (input) => {
        try {
          await ServiceUserPharmacistProfileContacts.patch(input);
          return { data: input };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    deleteUserPharmacistProfileContacts: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfileContacts.delete(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
  }),
});

import {
  AccountBalanceWallet,
  Dashboard,
  Event,
  Forum,
  Group,
  Menu,
  PermContactCalendar,
  Settings,
  Web,
} from '@mui/icons-material';
import { Box, Drawer } from '@mui/material';
import clsx from 'clsx';
import { ButtonTonal } from 'components/buttons/button-tonal';
import { APP_ROUTES } from 'configs';
import { useOpen, usePermissions, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { Languages } from '../languages';
import { MenuLogo } from '../menu-logo';
import { Navigation } from '../navigation';
import style from './index.module.scss';

interface Props {
  isResponsive?: boolean;
}

export const Panel: React.FC<Props> = ({ isResponsive = false }) => {
  const { tp } = useTranslate();

  const permissions = usePermissions();

  const {
    isOpen: isOpenDesktop,
    onToggle: onToggleDesktop,
    onClose: onCloseDesktop,
    onOpen: onOpenDesktop,
  } = useOpen();

  const {
    isOpen: isOpenMobile,
    onToggle: onToggleMobile,
    onClose: onCloseMobile,
    onOpen: onOpenMobile,
  } = useOpen();

  const options = useMemo(() => {
    const optionList = [
      {
        title: tp('dashboard'),
        Icon: (
          <Dashboard
            color={'inherit'}
            sx={{
              fontSize: 'inherit',
            }}
          />
        ),
        to: APP_ROUTES.DASHBOARD().config,
      },
      {
        title: tp('consult-sessions'),
        Icon: (
          <Forum
            color={'inherit'}
            sx={{
              fontSize: 'inherit',
            }}
          />
        ),
        to: APP_ROUTES.CONSULT_SESSIONS().config,
      },
    ];
    return permissions.isAdmin
      ? [
          ...optionList,
          {
            title: tp('pharmacists'),
            Icon: (
              <PermContactCalendar
                color={'inherit'}
                sx={{
                  fontSize: 'inherit',
                }}
              />
            ),
            options: [
              {
                title: tp('pharmacists-list'),
                to: APP_ROUTES.PHARMACISTS_LIST().config,
              },
              {
                title: tp('pharmacists-activities'),
                to: APP_ROUTES.PHARMACISTS_ACTIVITIES().config,
              },
              {
                title: tp('yearly-subscription'),
                to: APP_ROUTES.YEARLY_SUBSCRIPTIONS().config,
              },
              {
                title: tp('memberships'),
                to: APP_ROUTES.MEMBERSHIPS().config,
              },
              {
                title: tp('open-invoices'),
                to: APP_ROUTES.OPEN_INVOICES().config,
              },
            ],
          },
          {
            title: tp('expenses-list'),
            Icon: (
              <AccountBalanceWallet
                color={'inherit'}
                sx={{
                  fontSize: 'inherit',
                }}
              />
            ),
            options: [
              {
                title: tp('suppliers'),
                to: APP_ROUTES.SUPPLIERS().config,
              },
              {
                title: tp('invoices'),
                to: APP_ROUTES.INVOICES().config,
              },
            ],
          },
          {
            title: tp('settings'),
            Icon: (
              <Settings
                color={'inherit'}
                sx={{
                  fontSize: 'inherit',
                }}
              />
            ),
            options: [
              {
                title: tp('labels'),
                to: APP_ROUTES.LABELS().config,
              },
              {
                title: tp('general-settings'),
                to: APP_ROUTES.SETTINGS_GENERAL().config,
              },
              {
                title: tp('employees'),
                to: APP_ROUTES.USERS().config,
              },
              {
                title: tp('permissions'),
                to: APP_ROUTES.PERMISSIONS().config,
              },
              ...(permissions.isRoleOperation
                ? []
                : [
                    {
                      title: tp('consulted-form-settings'),
                      to: APP_ROUTES.CONSULTED_FORM_SETTINGS().config,
                    },
                  ]),
            ],
          },
          {
            title: tp('cms'),
            Icon: (
              <Web
                color={'inherit'}
                sx={{
                  fontSize: 'inherit',
                }}
              />
            ),
            options: [
              {
                title: tp('pages'),
                to: APP_ROUTES.CMS_PAGES().config,
              },
              {
                title: tp('articles'),
                to: APP_ROUTES.CMS_ARTICLES().config,
              },
              {
                title: tp('pharma-rules'),
                to: APP_ROUTES.CMS_PHARMA_RULES().config,
              },
              {
                title: tp('pharma-advisors'),
                to: APP_ROUTES.CMS_PHARMA_ADVISORS().config,
              },
              {
                title: tp('reg-drugs'),
                to: APP_ROUTES.CMS_REG_DRUGS().config,
              },
              {
                title: tp('site-parameters'),
                to: APP_ROUTES.CMS_SITE_PARAMETERS().config,
              },
              {
                title: tp('team-members'),
                to: APP_ROUTES.CMS_TEAM_MEMBERS().config,
              },
              {
                title: tp('website-settings'),
                to: APP_ROUTES.CMS_SETTINGS().config,
              },
            ],
          },
          {
            title: tp('customers'),
            Icon: (
              <Group
                color={'inherit'}
                sx={{
                  fontSize: 'inherit',
                }}
              />
            ),
            options: [
              {
                title: tp('customers-list'),
                to: APP_ROUTES.CUSTOMERS_LIST().config,
              },
            ],
          },
          {
            title: tp('event-sessions'),
            Icon: (
              <Event
                color={'inherit'}
                sx={{
                  fontSize: 'inherit',
                }}
              />
            ),
            to: APP_ROUTES.EVENT_SESSIONS().config,
          },
        ]
      : optionList;
  }, [tp, permissions.isAdmin, permissions.isRoleOperation]);

  return (
    <>
      {!isResponsive ? (
        <Drawer
          open={isOpenDesktop}
          variant={'permanent'}
          onClose={onCloseDesktop}
          classes={{
            root: clsx(style.root, isOpenDesktop && style.rootOpen),
            paper: clsx(style.paper, isOpenDesktop && style.paperOpen),
          }}
        >
          <Box className={style.inner}>
            <MenuLogo isOpen={isOpenDesktop} onToggle={onToggleDesktop} />
            <Box>
              <Navigation
                isMenuOpen={isOpenDesktop}
                onMenuOpen={onOpenDesktop}
                // onMenuClose={onCloseDesktop}
                options={options}
              />
            </Box>
            <Languages sx={{ mb: 3 }} />
          </Box>
        </Drawer>
      ) : (
        <>
          <ButtonTonal
            color={'primary'}
            onClick={onOpenMobile}
            sx={{
              minWidth: 36,
              padding: 0,
            }}
          >
            <Menu />
          </ButtonTonal>
          <Drawer
            open={isOpenMobile}
            onClose={onCloseMobile}
            classes={{
              paper: clsx(style.paper, style.paperOpen),
            }}
          >
            <Box className={style.inner}>
              <MenuLogo isOpen={isOpenMobile} onToggle={onToggleMobile} />
              <Box>
                <Navigation
                  isMenuOpen={isOpenMobile}
                  onMenuOpen={onOpenMobile}
                  onMenuClose={onCloseMobile}
                  options={options}
                />
              </Box>
              <Languages sx={{ mb: 1 }} />
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};

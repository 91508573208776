import CallMadeIcon from '@mui/icons-material/CallMade';
import { ButtonProps, ButtonTypeMap, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ButtonTonal } from 'components/buttons/button-tonal';
import React from 'react';
import { NavLink, To } from 'react-router-dom';
import style from './index.module.scss';

interface Props {
  className?: string;
  children: string;
  to: To;
  title?: React.ReactNode;
}

export const ButtonGoTo = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = {},
>({
  title,
  className,
  to,
  children,
  ...rest
}: Props & ButtonProps<D, P>) => {
  return (
    <Tooltip title={title || children}>
      <ButtonTonal
        component={NavLink}
        endIcon={<CallMadeIcon />}
        color={'primary'}
        className={clsx(style.root, className)}
        to={to}
        {...rest}
      >
        {children}
      </ButtonTonal>
    </Tooltip>
  );
};

import { ThemeOptions } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import variables from 'styles/config.scss';

const appBreakpoints = {
  values: {
    xs: Number(variables.xs),
    sm: Number(variables.sm),
    md: Number(variables.md),
    lg: Number(variables.lg),
    xl: Number(variables.xl),
    xxl: Number(variables.xxl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,

  typography: {
    fontFamily: 'Arial, Tahoma, Geneva, sans-serif',
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: '4rem',
      lineHeight: 1.2,
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '2.4rem',
      lineHeight: 1.33,
      fontWeight: 500,
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },

    subtitle1: {
      fontSize: '1.6rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.4rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: 1.4,
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      lineHeight: 0.8,
      textTransform: 'inherit',
      textAlign: 'left',
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: 1.25,
    },
  },

  palette: {
    background: {
      default: variables.colorGreyLight,
    },
    primary: {
      main: variables.colorPrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: variables.colorSecondary,
      dark: variables.colorSecondaryDark,
      contrastText: '#fff',
    },
    success: {
      main: variables.colorGreen,
    },
    error: {
      main: variables.colorRed,
    },
    text: {
      primary: variables.colorGreyDark,
      secondary: variables.colorGrey,
    },

    tonalOffset: 0.05,
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {},
        sizeLarge: {
          minHeight: '4.8rem',
        },
        sizeMedium: {
          minHeight: '3.6rem',
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        autoComplete: 'off',
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.358em',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1.358em',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderBottomColor: variables.colorGreyLines,
          },
        },
      },
    },

    MuiSwitch: {
      defaultProps: {
        color: 'success',
      },
      styleOverrides: {
        root: {
          height: 36,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: variables.colorGreyLight,
          paddingRight: 7,
          '&.Mui-focused': {
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled ': {
            opacity: 0.5,
          },
          '&.Mui-disabled  .MuiOutlinedInput-notchedOutline': {
            borderColor: variables.colorGreyLines,
          },
        },

        notchedOutline: {
          borderColor: variables.colorGreyLines,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: variables.colorFormLabel,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: 1,
          marginTop: '0',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2.4rem',
        },
        fontSizeSmall: {
          fontSize: '1.5rem',
        },
      },
    },

    MuiPopover: {},

    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },

    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          padding: '6px',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.8rem',
        },
        paperFullScreen: {
          borderRadius: '0',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2rem ',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 2rem 2rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '1.6rem 2rem',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderColor: variables.colorGreyLines,
        },
        rounded: {
          borderRadius: 12,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        gutters: {
          [breakpoints.up('xs')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6re',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
          [breakpoints.up('sm')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('xxl')]: {
            paddingLeft: '2.4rem',
            paddingRight: '2.4rem',
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: variables.colorGreyLines,
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1300,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: '0.25',
          },
        },
      },
    },
  },
};

import { createTypedRoute } from 'modules/typed-routes';

export enum CUSTOMER_ITEM_TABS {
  GENERAL = 'general',
  PAYMENTS = 'payments',
  PAID_BY = 'paidBy',
  CONTACTS = 'contacts',
  MEMBERSHIPS = 'memberships',
  SPONSORSHIPS = 'sponsorships',
  REG_DRUGS = 'reg-drugs',
}

export enum EVENT_SESSION_ITEM_TABS {
  GENERAL = 'general',
  USERS = 'users',
  MESSAGES = 'messages',
  PRICES = 'prices',
  PROGRAMS = 'programs',
  LINKS = 'links',
  FILES = 'files',
  SPONSORS = 'sponsors',
}

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  PHARMACISTS_LIST: createTypedRoute('/pharmacist/list'),
  PHARMACIST_ITEM: createTypedRoute<{ appIdentityUserID: string }>(
    '/pharmacist/list/:appIdentityUserID',
  ),
  PHARMACISTS_ACTIVITIES: createTypedRoute('/pharmacist/activities'),
  YEARLY_SUBSCRIPTIONS: createTypedRoute('/pharmacist/yearly-subscription'),
  MEMBERSHIPS: createTypedRoute('/pharmacist/memberships'),
  OPEN_INVOICES: createTypedRoute('/pharmacist/open-invoices'),
  PROFILE: createTypedRoute('/profile'),
  CONSULT_SESSIONS: createTypedRoute('/consult-sessions'),

  USERS: createTypedRoute('/settings/users'),
  USER_ITEM: createTypedRoute<{ appUserID: string | 'new' }>('/settings/users/:appUserID'),
  PERMISSIONS: createTypedRoute('/settings/permissions'),

  LABELS: createTypedRoute('/settings/labels'),
  LABELS_WEBSITE: createTypedRoute('/settings/labels/website'),
  LANGUAGES: createTypedRoute('/settings/labels/languages'),

  SETTINGS_GENERAL: createTypedRoute('/settings/general'),
  NAME_PREFIXES_ENG: createTypedRoute('/settings/general/name-prefixes-eng'),
  NATIONALITIES: createTypedRoute('/settings/general/nationalities'),
  MEMBER_TYPES: createTypedRoute('/settings/general/member-types'),
  NAME_PREFIXES: createTypedRoute('/settings/general/name-prefixes'),
  PAYMENT_TYPES: createTypedRoute('/settings/general/payment-types'),
  REGIONS: createTypedRoute('/settings/general/regions'),
  CITIES: createTypedRoute('/settings/general/cities'),
  WORK_PLACES: createTypedRoute('/settings/general/work-places'),
  WORK_PLACE_TYPES: createTypedRoute('/settings/general/work-place-types'),
  EXTERNAL_CHANNELS: createTypedRoute('/settings/general/external-channels'),
  CUSTOMER_TYPES: createTypedRoute('/settings/general/customer-types'),
  EVENT_SESSION_CATEGORIES: createTypedRoute('/settings/general/event-session-categories'),
  SMS_MESSAGES: createTypedRoute('/settings/general/sms-messages'),
  EMAIL_MESSAGES: createTypedRoute('/settings/general/email-messages'),
  PAYMENT_ACCOUNTS: createTypedRoute('/settings/general/payment-accounts'),
  INCOME_ACCOUNTS: createTypedRoute('/settings/general/income-accounts'),
  INVOICE_TYPES: createTypedRoute('/settings/general/invoice-types'),

  CONSULTED_FORM_SETTINGS: createTypedRoute('/settings/consulted-form'),
  RIGHTS_EXTRACTION_CLASSIFICATIONS: createTypedRoute(
    '/settings/consulted-form/rights-extraction-classifications',
  ),
  CLINICAL_CLASSIFICATIONS_SUBJECTS: createTypedRoute(
    '/settings/consulted-form/clinical-classification-subjects',
  ),
  LOGISTIC_CLASSIFICATIONS: createTypedRoute('/settings/consulted-form/logistic-classifications'),
  INSURANCE_STATUSES: createTypedRoute('/settings/consulted-form/insurance-statuses'),
  REFERRAL_TYPES: createTypedRoute('/settings/consulted-form/referral-types'),
  MEDICAL_STAFF_OPTIONS: createTypedRoute('/settings/consulted-form/medical-staff-options'),
  MEDICAL_STAFF_WORK_PLACES: createTypedRoute('/settings/consulted-form/medical-staff-work-places'),
  RESPONSIVENESS_TO_TREATMENTS: createTypedRoute(
    '/settings/consulted-form/responsiveness-to-treatments',
  ),
  REFERENCE_SOURCES: createTypedRoute('/settings/consulted-form/reference-sources'),
  GENDERS: createTypedRoute('/settings/consulted-form/genders'),

  INVOICES: createTypedRoute('/expenses-list/invoices'),
  SUPPLIERS: createTypedRoute('/expenses-list/suppliers'),

  CMS_PAGES: createTypedRoute('/cms/general/pages'),
  CMS_PAGE_CONTACT_US: createTypedRoute('/cms/general/pages/contact-us'),
  CMS_PAGE_THANKS: createTypedRoute('/cms/general/pages/thanks'),
  CMS_PAGE_SEARCH: createTypedRoute('/cms/general/pages/search'),
  CMS_PAGE_COURSES: createTypedRoute('/cms/general/pages/courses'),
  CMS_PAGE_FORUMS: createTypedRoute('/cms/general/pages/forums'),
  CMS_PAGE_WEBINARS: createTypedRoute('/cms/general/pages/webinars'),
  CMS_PAGE_PHARMA_RULES: createTypedRoute('/cms/general/pages/pharma-rules'),
  CMS_PAGE_DRUGS: createTypedRoute('/cms/general/pages/drugs'),
  CMS_PAGE_PHARMACISTS: createTypedRoute('/cms/general/pages/pharmacists'),
  CMS_PAGE_ARTICLES: createTypedRoute('/cms/general/pages/articles'),
  CMS_PAGE_ETHICS_CODE: createTypedRoute('/cms/general/pages/ethics-code'),
  CMS_PAGE_BENEFITS: createTypedRoute('/cms/general/pages/benefits'),

  CMS_ARTICLES: createTypedRoute('/cms/general/articles'),
  CMS_PHARMA_ADVISORS: createTypedRoute('/cms/general/pharma-advisors'),
  CMS_PHARMA_RULES: createTypedRoute('/cms/general/pharma-rules'),
  CMS_REG_DRUGS: createTypedRoute('/cms/general/reg-drugs'),
  CMS_REG_DRUG_ITEM: createTypedRoute<{ id: string }>('/cms/general/reg-drugs/:id'),
  CMS_SITE_PARAMETERS: createTypedRoute('/cms/general/site-parameters'),
  CMS_TEAM_MEMBERS: createTypedRoute('/cms/general/team-members'),

  CMS_SETTINGS: createTypedRoute('/cms/settings'),
  CMS_ARTICLE_SUBJECTS: createTypedRoute('/cms/settings/article-subjects'),
  CMS_TEAM_SUBJECTS: createTypedRoute('/cms/settings/team-subjects'),

  EVENT_SESSIONS: createTypedRoute('/event-sessions'),
  EVENT_SESSION_ITEM: createTypedRoute<{ id: string }>('/event-sessions/:id'),

  CUSTOMERS_LIST: createTypedRoute('/customers/list'),
  CUSTOMER_ITEM: createTypedRoute<{ id: string; invoiceID?: string }>('/customers/list/:id'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
};

import * as dynamic from 'utils/dynamic';
import {
  apiRtk,
  behaviourMoveRows,
  decoratorRank,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_LOGISTIC_CLASSIFICATIONS,
  IGridLogisticClassification,
  ILogisticClassification,
} from './models';

const REVALIDATE_KEY = 'LogisticClassifications' as const;

export * from './models';

type ApiModel = ILogisticClassification;

export interface IGridLogisticClassificationsParams {
  search: string;
  active: boolean | null;
  order: dynamic.DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<ApiModel> {
  @decoratorRank('rank')
  async post(data: Omit<ApiModel, 'id'>) {
    return super.post(data);
  }
  moveRows = async function (
    newRows: Partial<ApiModel>[],
    oldRows: Partial<ApiModel>[],
  ) {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: ServiceLogisticClassification.patch,
    });
  };
}

type SourceModel = Pick<ApiModel, 'id' | 'title' | 'isOther'>;

export const ServiceLogisticClassification = new Service({
  getAll: API_LOGISTIC_CLASSIFICATIONS.GET_ALL_DYNAMIC,
  post: API_LOGISTIC_CLASSIFICATIONS.POST,
  patch: API_LOGISTIC_CLASSIFICATIONS.PATCH,
  delete: API_LOGISTIC_CLASSIFICATIONS.DELETE,
});

export const apiLogisticClassifications = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridLogisticClassifications: build.query<
      DynamicResult<IGridLogisticClassification, { count: true }>,
      IGridLogisticClassificationsParams
    >({
      queryFn: async ({ search, active, order, take, skip }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title'], search, dynamic.contains),
                dynamic.makeFilter(
                  'isActive',
                  active,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'rank', 'isOther', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceLogisticClassification.getAllDynamic<
            IGridLogisticClassification,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLogisticClassification: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive', 'isOther', 'rank'),
          };
          const { data } = await ServiceLogisticClassification.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getSourceLogisticClassifications: build.query<SourceModel[], void>({
      query: () => ({
        url: API_LOGISTIC_CLASSIFICATIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title', 'isOther'),
          filter: dynamic
            .mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals))
            .join('&&'),
          orderBy: dynamic.orderBy('rank', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    postLogisticClassification: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLogisticClassification.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLogisticClassification: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLogisticClassification.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteLogisticClassification: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLogisticClassification.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    moveLogisticClassification: build.mutation<
      void,
      { newRows: Partial<ApiModel>[]; oldRows: Partial<ApiModel>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceLogisticClassification.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id })),
      ],
    }),
  }),
});

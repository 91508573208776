import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';

interface Classes {
  root: string;
}

interface Props extends ButtonProps {
  className?: string;
  classes?: Partial<Classes>;
}

export const ButtonAdd: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <Tooltip title={title || tp('add-tooltip')}>
      <Button startIcon={<AddIcon />} variant={'contained'} {...rest}>
        {children || tp('add')}
      </Button>
    </Tooltip>
  );
};

import { endOfYear, format, startOfYear } from 'date-fns';
import { IUserPharmacistProfileInvoiceDetails } from 'services/user-pharmacist-profile-invoice-details';
import { IUserPharmacistProfile } from 'services/user-pharmacist-profiles';
import { DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { InferType } from 'yup';
import { IUserPharmacistProfileContact } from '../user-pharmacist-profile-contacts';

const API = 'UserPharmacistProfileInvoices';

export const API_USER_PHARMACIST_PROFILE_INVOICES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPharmacistProfileInvoices, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPharmacistProfileInvoices, 'id'>) => `${API}/Delete/${data.id}`,
  SEND_EMAIL: `${API}/SendEmail`,
  VALIDATE_EXCEL: `${API}/ValidateExcel`,
  UPLOAD_BULK_INVOICE_DETAILS: `${API}/UploadBulkInvoiceDetails`,

  DUPLICATE: (invoiceID: string) => `${API}/Duplicate/${invoiceID}`,
  CONVERT_TO: `${API}/Convert`,
  RECEIPT: `${API}/Receipt`,
};

export interface UserPharmacistProfileInvoice
  extends DynamicComponents.Schemas.UserPharmacistProfileInvoice {
  id: string;
  userPharmacistProfileID: string;
}

export const schemaUserPharmacistProfileInvoiceSendEmail = yup.object({
  invoiceID: yup.string().nullable().required('rule-required'),
  email: yup.string().nullable().required('rule-required'),
});
export class UserPharmacistProfileInvoiceSendEmail
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoiceSendEmail>
{
  email = '';
  invoiceID = '';
}
export const schemaUserPharmacistProfileInvoice = yup.object({
  invoiceNumber: yup
    .string()
    .required('rule-required')
    .default(() => `P${format(new Date(), 'yyyyMMdd.HHmm')}`),
  userPharmacistProfileID: yup.string().required('rule-required').default(''),
  invoiceDate: yup
    .string()
    .required('rule-required')
    .default(() => new Date().toISOString()),
  invoiceTypeID: yup.string().required('rule-required').default(''),
  poNumber: yup.string().nullable().notRequired().default(''),
  remarks: yup.string().nullable().notRequired().max(250, 'rule-max-length').default(''),
  cardRemarks: yup.string().nullable().notRequired().max(250, 'rule-max-length').default(''),
});

export type UserPharmacistProfileInvoicePostInput = InferType<
  typeof schemaUserPharmacistProfileInvoice
>;

export const schemaUserPharmacistProfileInvoicePatch = schemaUserPharmacistProfileInvoice.shape({
  id: yup.string().required('rule-required').default(''),
  userPharmacistProfileContactID: yup.string().notRequired().nullable().default(null),
  ignore: yup.boolean().notRequired().nonNullable().default(undefined),
  internalRemarks: yup.string().notRequired().nullable().default(undefined),
});

export type UserPharmacistProfileInvoicePatchInput = InferType<
  typeof schemaUserPharmacistProfileInvoicePatch
>;

export class UserPharmacistProfileInvoices
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoice>
{
  id!: string;
  poNumber = '';
  isActive: boolean = true;
  userPharmacistProfileID!: string;
  invoiceNumber = '';
  invoiceDate = new Date().toISOString();
  paymentDate = new Date().toISOString();
  isPaid = false;
  isSent = false;
  invoiceURL = '';
  invoiceTypeID!: string;
  remarks = '';
  ignore = false;
  internalRemarks = '';
  cardRemarks = '';
  userPharmacistProfileContactID? = '';
}

export const schemaUserPharmacistProfileInvoiceImportFromExcel = yup.object({
  quantity: yup.number().integer('rule-integer').required('rule-required').default(1),
  paymentTypeID: yup
    .string()
    .required('rule-required')
    .default('7a541852-98dd-4179-a86f-ea307b87da11'),
  incomeAccountID: yup
    .string()
    .required('rule-required')
    .default('b894fa2a-d43b-4bea-9e61-8aecba41ac4c'),
  amount: yup.number().required('rule-required').default(0),
  userPharmacistProfileInvoiceID: yup.string().required('rule-required').default(''),
  fromDate: yup
    .string()
    .notRequired()
    .nullable()
    .default(() => startOfYear(new Date()).toISOString()),
  toDate: yup
    .string()
    .notRequired()
    .nullable()
    .default(() => endOfYear(new Date()).toISOString()),
  file: yup.mixed<string>().required('rule-required').default(undefined),
  discount: yup.number().nullable().notRequired().default(null),
  discountPercentage: yup.number().nullable().notRequired().default(null),
  remarks: yup.string().notRequired().nullable().default(null),
  duration: yup.number().required('rule-required').default(12),
});

export interface UserPharmacistProfileInvoicesExtended extends UserPharmacistProfileInvoices {
  contactName: string;
}
export interface IUserPharmacistProfileInvoices extends UserPharmacistProfileInvoices {
  userPharmacistProfile: IUserPharmacistProfile;
  userPharmacistProfileInvoiceDetails: IUserPharmacistProfileInvoiceDetails[];
  paymentRequired: boolean;
  'invoiceType.step': string;
  'userPharmacistProfileInvoiceDetails.count()': string;
}
export interface IGridUserPharmacistProfileInvoices
  extends Pick<
    IUserPharmacistProfileInvoices,
    | 'id'
    | 'isActive'
    | 'invoiceDate'
    | 'invoiceNumber'
    | 'paymentDate'
    | 'invoiceURL'
    | 'isPaid'
    | 'isSent'
    | 'invoiceTypeID'
    | 'paymentRequired'
    | 'internalRemarks'
    | 'cardRemarks'
    | 'ignore'
  > {
  userPharmacistProfileID: string;
  totalAmount: string;
  invoiceTypeTitle: string;
  step: number;
  contactName: IUserPharmacistProfileContact['name'];
}

export interface ConvertUserPharmacistProfileInvoicesInput {
  invoiceID: string;
  toInvoiceTypeID: string;
  internalRemarks: string;
  cardRemarks: string;
}
export interface CreateReceiptUserPharmacistProfileInvoicesInput {
  invoiceIDs: Array<string>;
  userPharmacistProfileID: string;
  invoiceTypeID: string;
  cardRemarks: string;
}

export interface IDashboardPaymentInvoices
  extends Pick<
    IUserPharmacistProfileInvoices,
    'id' | 'paymentDate' | 'invoiceNumber' | 'invoiceURL' | 'userPharmacistProfileID' | 'isPaid'
  > {
  paidBy: IUserPharmacistProfileInvoices['userPharmacistProfile']['fullName'];
  totalAmount: number;
}

export interface IDashboardPaymentInvoicesArgs {
  search: string;
  date: DateValue[];
  orderBy: DynamicOrder;
}

export interface IGridOpenInvoices extends Pick<IUserPharmacistProfileInvoiceDetails, 'id'> {
  invoiceNumber: IUserPharmacistProfileInvoices['invoiceNumber'];
  invoiceDate: IUserPharmacistProfileInvoices['invoiceDate'];
  cardRemarks: IUserPharmacistProfileInvoices['cardRemarks'];
  invoiceURL: IUserPharmacistProfileInvoices['invoiceURL'];
  poNumber: IUserPharmacistProfileInvoices['poNumber'];
  isSent: IUserPharmacistProfileInvoices['isSent'];
  name: IUserPharmacistProfileInvoices['userPharmacistProfile']['fullName'];
  userPharmacistProfileID: string;
  totalAmount: string;
  contactName: IUserPharmacistProfileContact['name'];
}

export interface IGridOpenInvoicesFilters {
  searchFirstName: string;
  searchLastName: string;
  search: string;
  invoiceNumber: string;
  date: DateValue[];
  active: boolean | null;
}

export interface IGridOpenInvoicesArgs extends IGridOpenInvoicesFilters {
  take: number;
  skip: number;
  order: DynamicOrder;
}
